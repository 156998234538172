import Graph from './Graph/Graph';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Spinner from 'src/components/Spinner/Spinner';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import RawConstants from 'src/components/CDV/RawConstants/RawConstants';
import ButtonExportConstants from 'src/components/CDV/ButtonExportConstants';
import FormThreshold from 'src/components/CDV/FormThreshold';
import PlageSchedule from 'src/components/CDV/Plage/PlageSchedule';
import useSocket from 'src/hooks/useSocket';
import useRequest from 'src/hooks/useRequest';
import { DATABASE_MODELS, URLS } from 'src/globals';
import { STATUS } from 'src/Enums';

const CDV = () => {
  const [searchParams] = useSearchParams();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const {
    request,
    data: patientInfos,
    loading
  } = useRequest(URLS.PATIENT.GET(searchParams.get('id')));

  const { request: sendDataRequest } = useRequest(URLS.MAIL.SEND_TO_PATIENT(), {
    body: {
      patient_id: searchParams.get('id'),
      subject: 'Vos données de constantes',
      content: `Voici vos données de constantes: ${
        process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://livome.fr'
      }/demo_patient?id=${searchParams.get('id')}`
    }
  });

  const [sendDataLoading, setSendDataLoading] = useState(false);

  const { data: constantsSocket, setData: setConstantsSocket } = useSocket({
    defaultValue: {
      temperature: [],
      pulse_rate: [],
      spo2: [],
      date: []
    },
    collection: DATABASE_MODELS.PATIENT,
    documentID: searchParams.get('id'),
    callback: (data, change) => {
      console.log(change);
      const value =
        change.fullDocument?.constants ?? change.updateDescription?.updatedFields?.constants;
      if (value) return value;
      if (change.updateDescription?.updatedFields) {
        for (const key of Object.keys(change.updateDescription.updatedFields)) {
          if (key.startsWith('constants')) {
            const [_, type, index] = key.split('.');
            data[type][parseInt(index)] = change.updateDescription.updatedFields[key];
          }
        }
      }
      return { ...data };
    }
  });

  const { data: status } = useSocket({
    defaultValue: patientInfos?.status ?? STATUS.UNKNOWN,
    collection: DATABASE_MODELS.PATIENT,
    documentID: searchParams.get('id'),
    callback: (data, change) =>
      change.fullDocument?.status ?? change.updateDescription?.updatedFields?.status ?? data
  });

  const [filteredSocket, setFilteredSocket] = useState({
    temperature: [],
    pulse_rate: [],
    spo2: [],
    date: []
  });

  useEffect(() => {
    if (!startDate && !endDate) return setFilteredSocket(constantsSocket);
    if (startDate && endDate) {
      const filtered = {
        temperature: [],
        pulse_rate: [],
        spo2: [],
        date: []
      };
      for (let i = 0; i < constantsSocket.date.length; i++) {
        const date = new Date(constantsSocket.date[i]);
        if (date >= startDate && date <= endDate) {
          filtered.temperature.push(Number(constantsSocket.temperature[i]).toFixed(2));
          filtered.pulse_rate.push(Number(constantsSocket.pulse_rate[i]).toFixed(2));
          filtered.spo2.push(Number(constantsSocket.spo2[i]).toFixed(2));
          filtered.date.push(constantsSocket.date[i]);
        }
      }
      return setFilteredSocket(filtered);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setFilteredSocket(constantsSocket);
  }, [constantsSocket]);

  useEffect(() => {
    request().then(({ data }) => {
      console.log(data);
      setConstantsSocket(data.constants);
    });
  }, []);

  const resetPlage = () => {
    setStartDate(null);
    setEndDate(null);
  };

  if (loading) return <Spinner />;

  const statusColor = {
    [STATUS.STABLE]: 'text-green',
    [STATUS.UNSTABLE]: 'text-red',
    [STATUS.UNKNOWN]: 'text-normal'
  };

  const sendData = () => {
    setSendDataLoading(true);
    sendDataRequest().then(() => setSendDataLoading(false));
  };

  return (
    <>
      <BreadcrumbL
        links={[
          ['/', 'Accueil'],
          ['/patients', 'Patients'],
          ['/patient', `${patientInfos.name} ${patientInfos.last_name}`]
        ]}
      />
      <Row>
        <Col xs={12} className="my-1 mb-3">
          <h3>Informations du patient</h3>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} lg={6}>
                  <Table className="table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="" style={{ width: '10%' }}>
                          <span className="text-muted">Nom</span>
                        </td>
                        <td>
                          {patientInfos.name} {patientInfos.last_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="text-muted">Pathologie</span>
                        </td>
                        <td>{patientInfos.pathology}</td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="text-muted">Statut</span>
                        </td>
                        <td>
                          <span
                            className={statusColor[status]}
                            style={{
                              fontWeight: 'bold'
                            }}>
                            {status}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs={12} lg={6}>
                  <Table className="table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td style={{ width: '10%' }}>
                          <span className="text-muted">Téléphone</span>
                        </td>
                        <td>
                          {patientInfos.phone_number ? patientInfos.phone_number : 'non renseigné'}
                        </td>
                      </tr>
                      <tr>
                        <td className="">
                          <span className="text-muted">Email</span>
                        </td>
                        <td>{patientInfos.email ? patientInfos.email : 'non renseigné'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6} className="my-1 mb-3">
          <h3>Données brutes</h3>
          <Card>
            <Card.Body>
              <div className="smartList" data-cy="cdv-rawconstants">
                {filteredSocket.date.length === 0 ? (
                  <span>Pas de données</span>
                ) : (
                  <RawConstants constants={filteredSocket} />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6} className="my-1 mb-3">
          <h3>Données graphiques</h3>
          <Card>
            <Card.Body data-cy="cdv-graph">
              {filteredSocket.date.length === 0 ? (
                <span>Pas de données</span>
              ) : (
                <Graph constants={filteredSocket} />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6} className="my-1 mb-3">
          <h3>Plage horaire</h3>
          <Card>
            <Card.Body>
              <PlageSchedule
                startDate={startDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                endDate={endDate}
              />
              <br />
              <Button onClick={resetPlage} data-cy="cdv-schedule-reset">
                Reset
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6} className="my-1 mb-3">
          <h3>Seuils</h3>
          <Card>
            <Card.Body>
              <FormThreshold patient={patientInfos} />
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} lg={6} className="my-1 mb-3">
          <h3>Export</h3>
          <Card>
            <Card.Body>
              <ButtonExportConstants patient={patientInfos} />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6} className="my-1 mb-3">
          <h3>Envoyer</h3>
          <Card>
            <Card.Body>
              {sendDataLoading ? (
                <Spinner />
              ) : (
                <Button onClick={sendData}>Envoyer les données au patient</Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CDV;
