import { Link } from 'react-router-dom';
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { PersonLinesFill, HospitalFill, GearFill } from 'react-bootstrap-icons';
import DashboardAlert from './DashboardAlert';
import IsAdminComponent from '../Authentification/RouteGuard/IsAdminComponent';

const Dashboard = () => {
  return (
    <>
      <Row>
        <Col className="col-12 col-lg-6">
          <DashboardAlert />
        </Col>
        <Col className="col-12 col-lg-6">
          <Row>
            <Col xs={12}>
              <h3>Liens rapides</h3>
            </Col>
            <IsAdminComponent>
              <Col className="col-12 col-sm-6 col-md-4 my-2">
                <Link to="/entities" data-cy="link-entities">
                  <Card>
                    <Card.Body>
                      <Card.Title></Card.Title>
                      <Card.Subtitle className="text-muted"></Card.Subtitle>
                      <Row>
                        <Col className="text-center text-blue-secondary">
                          <HospitalFill className="mb-1" size={20} />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">Entités</Col>
                      </Row>
                      {/* <Card.Link href="/entities">Lien</Card.Link> */}
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            </IsAdminComponent>
            <Col className="col-12 col-sm-6 col-md-4 my-2">
              <Link to="/patients" data-cy="link-patients">
                <Card>
                  <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Subtitle className="text-muted"></Card.Subtitle>
                    <Row>
                      <Col className="text-center text-blue-secondary">
                        <PersonLinesFill className="mb-1" size={20} />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center text-secondary">Patients</Col>
                    </Row>
                    {/* <Card.Link href="/entities">Lien</Card.Link> */}
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col className="col-12 col-sm-6 col-md-4 my-2">
              <Link to="/profile" data-cy="link-profile">
                <Card>
                  <Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Subtitle className="text-muted"></Card.Subtitle>
                    <Row>
                      <Col className="text-center text-blue-secondary">
                        <GearFill className="mb-1" size={20} />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center text-secondary">Compte</Col>
                    </Row>
                    {/* <Card.Link href="/entities">Lien</Card.Link> */}
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
