import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ModeEdit from '@mui/icons-material/ModeEdit';
import PropTypes from 'prop-types';

const EditButton = ({ patient }) => (
  <div data-cy="list-search-edit" className="text-secondary">
    <Link to={'/modify_patient?id=' + patient._id} className="text-secondary">
      <IconButton aria-label="ModeEdit" className="text-secondary">
        <ModeEdit className="text-secondary" />
      </IconButton>
    </Link>
  </div>
);

EditButton.propTypes = {
  patient: PropTypes.object.isRequired
};

export default EditButton;
