import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import UserActions from './UserActions';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <span
      style={{
        position: 'absolute',
        top: 10,
        left: 10
      }}
      data-cy="list-search-add">
      <Link to={'/add_user'}>
        <FaPlus
          className="addButton"
          style={{
            marginRight: 10
          }}
        />
        Ajouter un utilisateur
      </Link>
    </span>
    <TextField
      id="input-with-icon-textfield"
      type="text"
      InputProps={{
        'data-cy': 'list-search-input',
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
      placeholder="Rechercher un user"
      variant="standard"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

FilterComponent.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired
};

export const SearchUser = ({ fetchUsers, data }) => {
  const [filterText, setFilterText] = useState('');
  const filteredItems = data.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.last_name && item.last_name.toLowerCase().includes(filterText.toLowerCase()))
  );
  const navigate = useNavigate();
  const { request: deleteUser } = useRequest(URLS.USER.DELETE(null));

  const columns = [
    {
      id: 1,
      name: 'Prénom',
      cell: (row) => <span data-cy="list-search-row">{row.name}</span>,
      selector: (row) => row.name,
      sortable: true,
      reorder: true
    },
    {
      id: 2,
      name: 'Nom',
      cell: (row) => row.last_name,
      selector: (row) => row.last_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: 'Role',
      cell: (row) => row.role,
      selector: (row) => row.role,
      sortable: true,
      reorder: true
    },
    {
      id: 4,
      name: 'Action',
      cell: (row) => (
        <UserActions
          user={row}
          onModifyClick={() => navigate(`/modify_user?id=${row._id}`)}
          onDeleteClick={() => deleteUser({}, URLS.USER.DELETE(row._id)).then(fetchUsers)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ];

  return (
    <DataTable
      columns={columns}
      data={filteredItems}
      subHeader
      subHeaderComponent={
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} filterText={filterText} />
      }
      pagination
    />
  );
};

SearchUser.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default SearchUser;
