import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ toggleChange }) => {
  const [isChecked] = useState(true);

  return <input type="checkbox" defaultChecked={isChecked} onChange={toggleChange} />;
};

Checkbox.propTypes = {
  toggleChange: PropTypes.func.isRequired
};

export default Checkbox;
