import React from 'react';
import useSocket from 'src/hooks/useSocket';
import PropTypes from 'prop-types';
import { STATUS } from 'src/Enums';
import { DATABASE_MODELS } from 'src/globals';

const Status = ({ patient }) => {
  const { data: statusSocket } = useSocket({
    collection: DATABASE_MODELS.PATIENT,
    documentID: patient._id,
    actions: ['update', 'replace'],
    defaultValue: patient.status,
    callback: (data, change) =>
      change.fullDocument?.status ?? change.updateDescription?.updatedFields?.status ?? data
  });

  const statusColor = {
    [STATUS.STABLE]: 'text-green',
    [STATUS.UNSTABLE]: 'text-red',
    [STATUS.UNKNOWN]: 'text-normal'
  };

  return (
    <label data-cy="list-search-status">
      <span style={{ fontWeight: 'bold' }} className={statusColor[statusSocket]}>
        {statusSocket}
      </span>
    </label>
  );
};

Status.propTypes = {
  patient: PropTypes.object.isRequired
};

export default Status;
