const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: '#FFA500'
    }
  }
};
export { tableCustomStyles };
