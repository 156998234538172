import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext';
import { Row, Col, Card } from 'react-bootstrap';
import './Accessibility.css';

const Accessibility = () => {
  const { theme, toggleTheme, family, toggleFamily, size, toggleSize } = useContext(ThemeContext);

  return (
    <>
      <Row className="mt-3 mt-md-4">
        <Col xs={12}>
          <h3>Paramètres d'accessbilité</h3>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 col-md-6 my-2">
          <h5>Thème</h5>
          <Card>
            <Card.Body>
              <Card.Title></Card.Title>
              <Card.Subtitle className="text-muted"></Card.Subtitle>
              <div className="container-footer-theme text-center text-md-start mt-3 mb-3">
                <div className="container-footer-theme">
                  <div className="tabs-footer-theme">
                    <input
                      type="radio"
                      className="radio-footer"
                      id="radio-accessibility-theme-1"
                      name="tabs-footer-theme"
                      onChange={toggleTheme}
                      checked={theme == 'light'}
                    />
                    <label
                      className="tab-footer-theme text-primary"
                      htmlFor="radio-accessibility-theme-1">
                      Clair
                    </label>
                    <input
                      type="radio"
                      className="radio-footer"
                      id="radio-accessibility-theme-2"
                      name="tabs-footer-theme"
                      onChange={toggleTheme}
                      checked={theme == 'dark'}
                    />
                    <label
                      className="tab-footer-theme text-primary"
                      htmlFor="radio-accessibility-theme-2">
                      Sombre
                    </label>
                    <span className="glider-footer-theme"></span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-md-6 my-2">
          <h5>Police</h5>
          <Card>
            <Card.Body>
              <Card.Title></Card.Title>
              <Card.Subtitle className="text-muted"></Card.Subtitle>
              <div className="container-footer-font text-center text-md-start mt-3 mb-3">
                <div className="container-footer-font">
                  <div className="tabs-footer-font">
                    <input
                      type="radio"
                      className="radio-footer"
                      id="radio-accessibility-font-1"
                      name="tabs-footer-font"
                      onChange={toggleFamily}
                      checked={family == 'classic'}
                    />
                    <label
                      className="tab-footer-font text-primary"
                      htmlFor="radio-accessibility-font-1">
                      Classique
                    </label>
                    <input
                      type="radio"
                      className="radio-footer"
                      id="radio-accessibility-font-2"
                      name="tabs-footer-font"
                      onChange={toggleFamily}
                      checked={family == 'handwriting'}
                    />
                    <label
                      className="tab-footer-font text-primary"
                      htmlFor="radio-accessibility-font-2">
                      Handwriting
                    </label>
                    <span className="glider-footer-font"></span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-md-6 my-2">
          <h5>Taille de la police</h5>
          <Card>
            <Card.Body>
              <Card.Title></Card.Title>
              <Card.Subtitle className="text-muted"></Card.Subtitle>
              <div className="container-footer-fontsize text-center text-md-start mt-3 mb-3">
                <div className="container-footer-fontsize">
                  <div className="tabs-footer-fontsize">
                    <input
                      type="radio"
                      className="radio-footer"
                      id="radio-accessibility-fontsize-1"
                      name="tabs-footer-fontsize"
                      onChange={toggleSize}
                      checked={size == '100'}
                    />
                    <label
                      className="tab-footer-fontsize text-primary"
                      htmlFor="radio-accessibility-fontsize-1">
                      100%
                    </label>
                    <input
                      type="radio"
                      className="radio-footer"
                      id="radio-accessibility-fontsize-2"
                      name="tabs-footer-fontsize"
                      onChange={toggleSize}
                      checked={size == '110'}
                    />
                    <label
                      className="tab-footer-fontsize text-primary"
                      htmlFor="radio-accessibility-fontsize-2">
                      110%
                    </label>
                    <span className="glider-footer-fontsize"></span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Accessibility;
