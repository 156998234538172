import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './Footer.css';

const Footer = () => (
  <div data-cy="footer-div">
    <footer className="text-center text-lg-start text-muted footer-liv">
      {process.env.NODE_ENV === 'development' ? (
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Vous êtes dans un environnement de développement</span>
          </div>

          <div></div>
        </section>
      ) : null}

      <section className="p-2">
        <Container className="text-center text-md-start mt-5">
          <Row className="mt-3">
            <Col md="3" lg="4" xl="3" className="mx-auto mb-4">
              <span className="text-uppercase fw-bold mb-4">Livome</span>
              <p>
                Dématérialiser le cahier de vie des patients dans le cadre du suivi médical à
                domicile en s'assurant que les patients fassent l'objet d'un suivi de leur activité.
              </p>
            </Col>

            <Col md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <span className="text-uppercase fw-bold mb-4">Application</span>
              <p>
                <a href="/terms_of_use" className="text-reset">
                  Conditions générales
                </a>
              </p>
              <p>
                <a href="/support" className="text-reset">
                  Aide
                </a>
              </p>
            </Col>
            <Col md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <span className="text-uppercase fw-bold mb-4">Contact</span>
              <p>Toulouse, FRANCE</p>
              <p>contact.livome@gmail.com</p>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        ©{' '}
        <a className="text-reset fw-bold" href="https://livome.fr/">
          Livome
        </a>{' '}
        - 2023
      </div>
    </footer>
  </div>
);

export default Footer;
