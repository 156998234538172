import './Login.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CustomModal from '../Modals/CustomModal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';

const Forgot = () => {
  const [email, setEmail] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [message, setMessage] = useState(null);
  const { request: requestForgotPassword } = useRequest(URLS.AUTH.FORGOT(), {
    body: { email }
  });

  const handleSubmitForgotPassword = (event) => {
    event.preventDefault();
    requestForgotPassword().then(({ data, response }) => {
      setMessage(data.message);
    });
  };

  const resetModal = () => {
    setEmail(null);
    setShowForgotPassword(false);
    setMessage(null);
  };

  return (
    <Row>
      <Col className="col-12 text-center mt-2">
        <em className="font-90">
          <a onClick={setShowForgotPassword} className="text-center">
            <div>Première connexion /</div> Mot de passe oublié ?
          </a>
        </em>
        <CustomModal
          show={showForgotPassword}
          onHide={() => resetModal()}
          title="Récupération de compte"
          isPrompt={false}
          submitButton={{
            variant: 'secondary',
            text: 'Fermer',
            submit: () => {
              resetModal();
            }
          }}>
          {message === null ? (
            <Form noValidate onSubmit={handleSubmitForgotPassword}>
              <Row>
                <Col xs={12}>
                  <Form.Group as={Col} md="12" className="m-0" data-cy="login-form">
                    <FloatingLabel controlId="floatingEmail" label="Adresse mail" className="">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        className=""
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        data-cy="email-input"
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={12} className="mt-2">
                  <Button
                    type="submit"
                    className="btn btn-block w-100 bg-blue-primary"
                    data-cy="login-button">
                    Envoyer
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Row>
              <Col className="col-12 mt-2">{message}</Col>
            </Row>
          )}
        </CustomModal>
      </Col>
    </Row>
  );
};

export default Forgot;
