import React from 'react';
import { useState } from 'react';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import { Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap';

const Contact = () => {
  const { request: requestEmail } = useRequest(URLS.MAIL.SEND_FROM_USER_TO_STAFF());

  const [emailData, setEmailData] = useState({
    name: '',
    email: '',
    subject: '',
    content: ''
  });

  const sendEmail = (e) => {
    e.preventDefault();
    requestEmail(emailData);
    e.target.reset();
  };
  return (
    <Row>
      <Col>
        <section>
          <div name="form-box">
            <Form onSubmit={sendEmail} className="test">
              <Row>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatingFirstName" label="Nom">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        onChange={(event) => {
                          setEmailData({ ...emailData, name: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatingFirstName" label="Email">
                      <Form.Control
                        required
                        type="email"
                        placeholder=" "
                        onChange={(event) => {
                          setEmailData({ ...emailData, email: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatingFirstName" label="Sujet">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        onChange={(event) => {
                          setEmailData({ ...emailData, subject: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Votre Message</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={8}
                    onChange={(event) => {
                      setEmailData({ ...emailData, content: event.target.value });
                    }}
                  />
                </Form.Group>
                <Col xs={12} className="mb-3">
                  <Button type="submit">Envoyer le formulaire</Button>
                </Col>
              </Row>
            </Form>
          </div>
        </section>
      </Col>
    </Row>
  );
};

export default Contact;
