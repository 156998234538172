const prodUrl = 'https://livome.fr/api';
const devUrl = 'http://localhost:8081';

if (!localStorage.getItem('theme')) localStorage.setItem('theme', 'light');
if (!localStorage.getItem('font_family')) localStorage.setItem('font_family', 'classic');
if (!localStorage.getItem('font_size')) localStorage.setItem('font_size', '100');

console.log(process.env.NODE_ENV);
export const socketURL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://livome.fr:8081';
export default process.env.NODE_ENV === 'development' ? devUrl : prodUrl;
