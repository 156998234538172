import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useCurrentUser from '../../../hooks/useCurrentUser';
import Spinner from '../../Spinner/Spinner';

const ShouldBeLoggedIn = () => {
  const { user, loading } = useCurrentUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/login');
    if (user?.role === 'STAFF') return navigate('/simulation');
  }, [user, loading, navigate]);
  if (loading) return <Spinner />;
  return <Outlet />;
};

export default ShouldBeLoggedIn;
