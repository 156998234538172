import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { socketURL } from 'src/Env';
import { DatabaseModels } from 'src/globals';

export const socket = io(socketURL, { transports: ['websocket'] });

const isDeepEqual = (object1, object2) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

const isObject = (object) => {
  return object != null && typeof object === 'object';
};

const useSocket = ({
  collection = null,
  documentID = null,
  actions = ['insert', 'delete', 'replace', 'update'],
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  callback = (_currentData: unknown, _change: unknown) => _currentData,
  defaultValue = null
}: {
  collection: DatabaseModels | null;
  documentID?: string | null;
  actions?: string[];
  callback?: (currentData: unknown, change: unknown) => unknown;
  defaultValue?: unknown;
}) => {
  const [data, setData] = useState<unknown>(defaultValue);
  const [prevDefaultValue, setPrevDefaultValue] = useState<unknown>(defaultValue);

  useEffect(() => {
    if (!collection) return;
    if (!localStorage.getItem('userToken')) return;
    const unsubscribe = () => {
      socket.emit('unsubscribe', { collection, documentID });
      actions.forEach((action) => {
        socket.off(`${collection}${documentID ? `/${documentID}` : ''}/${action}`);
      });
    };
    // window.addEventListener('beforeunload', unsubscribe);
    socket.emit('subscribe', {
      collection,
      documentID,
      token: JSON.parse(localStorage.getItem('userToken')!)
    });
    actions.forEach((action) => {
      // console.log(`${collection}/${action}${documentID && `/${documentID}`}`);
      socket.on(`${collection}${documentID ? `/${documentID}` : ''}/${action}`, (change) => {
        setData((oldState: unknown) => callback(oldState, change));
      });
    });

    return () => {
      unsubscribe();
      // window.removeEventListener('beforeunload', unsubscribe);
    };
  }, [collection, documentID]);

  useEffect(() => {
    if (!defaultValue) return;
    if (
      defaultValue === prevDefaultValue ||
      isDeepEqual(defaultValue, prevDefaultValue) ||
      isDeepEqual(defaultValue, data)
    )
      return;
    setPrevDefaultValue(defaultValue);
    setData(defaultValue);
  }, [defaultValue]);

  return { data, setData };
};

export default useSocket;
