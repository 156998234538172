import { useState } from 'react';
import { Row, Col, Image, Accordion } from 'react-bootstrap';
import LivomeImg from 'src/assets/images/livome.png';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import Contact from './Contact';

const Support = () => {
  const [dashboard_open, setDashboardOpen] = useState(false);
  const [profil_user_open, setProfilUserOpen] = useState(false);
  const [list_patient_open, setListPatientOpen] = useState(false);
  const [profil_patient_open, setProfilPatientOpen] = useState(false);
  const [page_appel_open, setPageAppelOpen] = useState(false);
  const [list_entity_open, setListEntityOpen] = useState(false);
  const [livomeQst_open, setLivomeQstOpen] = useState(false);
  const [fonctionnementLivomeQst_open, setFonctionnementLivomeQstOpen] = useState(false);
  const [donneesSanteQst_open, setDonneesSanteQstOpen] = useState(false);
  const [creerUtilisateurQst_open, setCreerUtilisateurQstOpen] = useState(false);
  const [creerPatientQst_open, setCreerPatientQstOpen] = useState(false);
  const [contactSupportQst_open, setContactSupportQstOpen] = useState(false);
  const [echangerPersonnelSoignantQst_open, setEchangerPersonnelSoignantQstOpen] = useState(false);

  const handleEchangerPersonnelSoignantQstClick = () => {
    setEchangerPersonnelSoignantQstOpen(!echangerPersonnelSoignantQst_open);
  };

  const handleContactSupportQstClick = () => {
    setContactSupportQstOpen(!contactSupportQst_open);
  };

  const handleCreerPatientQstClick = () => {
    setCreerPatientQstOpen(!creerPatientQst_open);
  };

  const handleCreerUtilisateurQstClick = () => {
    setCreerUtilisateurQstOpen(!creerUtilisateurQst_open);
  };

  const handleDonneesSanteQstClick = () => {
    setDonneesSanteQstOpen(!donneesSanteQst_open);
  };

  const handleFonctionnementLivomeQstClick = () => {
    setFonctionnementLivomeQstOpen(!fonctionnementLivomeQst_open);
  };

  const handleLivomeQstClick = () => {
    setLivomeQstOpen(!livomeQst_open);
  };

  const handleDashboardClick = () => {
    setDashboardOpen(!dashboard_open);
  };

  const handleProfilUserClick = () => {
    setProfilUserOpen(!profil_user_open);
  };

  const handleListPatientClick = () => {
    setListPatientOpen(!list_patient_open);
  };

  const handleProfilPatientClick = () => {
    setProfilPatientOpen(!profil_patient_open);
  };

  const handlePageAppelClick = () => {
    setPageAppelOpen(!page_appel_open);
  };

  const handleListEntityClick = () => {
    setListEntityOpen(!list_entity_open);
  };

  return (
    <div data-testid="termofuse-div">
      <BreadcrumbL
        links={[
          ['/', 'Accueil'],
          ['/support', 'Page de support']
        ]}
      />
      <Row>
        <Col xs="12">
          <Row className="text-center mt-3 mb-2">
            <Col xs="12">
              <h1>
                <strong>Centre d'aide</strong>
              </h1>
            </Col>
          </Row>
          <Row className="text-center mt-4 mb-2">
            <Col xs="12">
              <h5>Informations et formulaire de contact</h5>
              <em>Mise à jour le 4 avril 2023</em>
            </Col>
          </Row>
          <Row className="text-center mt-3 mb-2">
            <Col xs="12">
              <Image src={LivomeImg} height="100px" />
            </Col>
          </Row>
          <Row className="mt-5 mb-2">
            <Col xs="12">
              <h4>
                <strong>Liste des fonctionnalités Livome à disposition des utilisateurs</strong>
              </h4>
            </Col>
            <Col xs="12">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header onClick={handleDashboardClick}>
                    Tableau de bord
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>Accéder à la liste des patients, des montres et des entités</li>
                      <li>Accéder à votre profil</li>
                      <li>Voir les patients en alerte</li>
                      <li>Accéder au contenu de vos notifications</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header onClick={handleProfilUserClick}>
                    Profil utilisateur
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>Modifier vos informations</li>
                      <li>Configurer le(s) type(s) d'alerte que vous souhaitez recevoir</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={handleListPatientClick}>
                    Liste des patients
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>Ajouter un patient</li>
                      <li>Filtrer la liste des patients en fonctions des entités</li>
                      <li>
                        Filtrer la liste des patients par leur nom/prénom via une barre de recherche
                      </li>
                      <li>Voir le profil d'un patient, le modifier ou le supprimer</li>
                      <li>Vérifier leur status</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header onClick={handleProfilPatientClick}>
                    Profil d'un patient
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>Voir les constantes d’un patient dans un tableau ou dans un graphique</li>
                      <li>Choisir les constantes qui sont affichées dans le graphique</li>
                      <li>Afficher les constantes selon une plage horaire</li>
                      <li>Exporter les données du patient en JSON, CSV ou XLSX</li>
                      <li>Consulter et modifier les limites du patient</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header onClick={handleListEntityClick}>
                    Liste des entités
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>Ajouter une entité via un arbre</li>
                      <li>Supprimer une entité via un arbre</li>
                      <li>Ajouter une entité enfant via un arbre</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header onClick={handlePageAppelClick}>
                    Vidéo-conférence
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>Créer une vidéo-conférence</li>
                      <li>Rejoindre une vidéo-conférence à l'aide d'un code</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row className="mt-5 mb-2">
            <Col xs="12">
              <h4>
                <strong>Foire aux questions</strong>
              </h4>
            </Col>
            <Col xs={12}>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header onClick={handleLivomeQstClick}>
                    Qu'est-ce que Livome ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        De nos jours il est difficile de se déplacer en zone urbaine pour les
                        infirmiers, c'est également une perte de temps et d'énergie pour eux. De
                        plus, le stockage des données de la part des médecins ou infirmiers est
                        archaïque : il n'y a pas de centralisation des données. Livome révolutionne
                        ce systeme par un suivi des patients à distances tout en restant fiable
                        grâce à la récupération des constantes d'un patient en temps réel, un system
                        d'alerte personnalisable, un historique des données et également une
                        centralisation des données."
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header onClick={handleFonctionnementLivomeQstClick}>
                    Comment fonctionne Livome ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        Chaque patient possède un bracelet Livome qui récupère des données (des
                        constantes tels que la temperéature ou le rythme cardiaque) en temps réel.
                        Ces données sont envoyées de façon sécurisé à un boitier préalablement
                        installé chez le patient et connecté par internet. Ce boitier envoie par la
                        suite les données du patient à l'hopital ou la clinique en charge du
                        patient. Ainsi, au moindre problème un personnel soignant peut intervenir au
                        plus vite grâce aux données en temps réel.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header onClick={handleDonneesSanteQstClick}>
                    Les données de santé récupérées par Livome sont-elles sécurisées ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        Livome respecte une norme française appelée Hébergeur de Données de Santé
                        (HDS) qui s'applique aux hébergeurs de données de santé en France. Un
                        hébergeur de données de santé fournit des services d'hébergement pour les
                        données de santé, telles que les constantes récupérées par Livome. Ces
                        données sont protégées par le secret médical et sont stockées de manière
                        sécurisée pour garantir la confidentialité, l'intégrité et la disponibilité
                        des informations. Livome respecte les exigences techniques et
                        organisationnelles spécifiques en matière de sécurité et de confidentialité
                        des données de santé, telles que la protection contre les accès non
                        autorisés, la sauvegarde et la restauration des données, la traçabilité des
                        accès, etc. Ces exigences sont définies par la norme HDS et sont
                        réglementées en France par l'Agence des Systèmes d'Information Partagés de
                        Santé (ASIP Santé).
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header onClick={handleCreerUtilisateurQstClick}>
                    Comment créer un compte utilisateur sur la plateforme Livome ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        Les droits de création d'un utilisateur sont réservés aux administrateurs de
                        la plateforme Livome. Si vous souhaitez rajouter un utilisateur vous devez
                        obligatoirement contacter un administrateur et/ou l'équipe technique en
                        charge de la plateforme.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header onClick={handleCreerPatientQstClick}>
                    Comment ajouter un patient sur la plateforme Livome ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        En tant qu'utilisateur, vous pouvez ajouter un nouveau patient directement
                        via la liste des patients qui est à votre disposition sur le dashboard de la
                        plateforme. Lorsque vous êtes sur la liste des patients, vous pouvez ajouter
                        un nouveau patient grâce à un bouton +. Vous serez redirigé vers un
                        formulaire d'ajout de patient où vous devrez remplir les informations du
                        patient que vous souhaitez ajouter.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header onClick={handleEchangerPersonnelSoignantQstClick}>
                    Comment échanger avec d'autres professionnels de santé sur la plateforme ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        Vous pouvez créer une vidéo-conférence et discuter avec d'autres utilisateur
                        à l'aide de l'appel vidéo (disponible sur la sidebar). Il vous suffit de
                        créer une video-conférence et de partager le code d'accès à toutes les
                        personnes que vous souhaitez inviter lors de cet échange. Seuls les
                        personnes ayant le code d'accès pourront rejoindre votre appel.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header onClick={handleContactSupportQstClick}>
                    Comment signaler un problème technique ou contacter l'équipe de support de la
                    plateforme ?
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        Lorsque vous rencontrez un problème d'utilisation sur la plateforme ou si
                        vous avez besoin d'assistance vous pouvez contacter l'équipe de support à
                        tout moment grâce au formulaire de contact. L'équipe en charge de la
                        plateforme vous répondra au plus vite sur votre mail. N'hésitez pas à
                        fournir des détails précis de votre problème.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row className="mt-5 mb-2">
            <Col xs="12">
              <h4>
                <strong>Besoin d'aide ? Contactez l'équipe technique via ce formulaire</strong>
              </h4>
            </Col>
            <Col xs="12">
              <Contact />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Support;
