import './style.scss';
import { FaTrash, FaPlus, FaPen } from 'react-icons/fa';
import { Row, Card, Col } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import CustomModal from '../Modals/CustomModal';
import useRequest from 'src/hooks/useRequest';
import PropTypes from 'prop-types';
import { URLS } from 'src/globals';
import Spinner from '../Spinner/Spinner';

const Tree = ({ motherEntity, refetch }) => {
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const { request: deleteRequest } = useRequest(URLS.MOTHERENTITY.DELETE(motherEntity._id));
  const { request: createRequest } = useRequest(URLS.MOTHERENTITY.CREATE());
  const { request: updateRequest } = useRequest(URLS.MOTHERENTITY.UPDATE(''));
  const { request: updateMotherRequest } = useRequest(URLS.MOTHERENTITY.UPDATE(motherEntity._id));

  const deleteEntity = () => {
    setModalProps({
      title: 'Supprimer une entité',
      onHide: () => setShowPromptModal(false),
      isPrompt: false,
      submitButton: {
        text: 'Supprimer',
        variant: 'danger',
        submit: () => deleteRequest().then(() => refetch())
      },
      cancelButton: {
        text: 'Annuler',
        variant: 'secondary'
      }
    });
    setShowPromptModal(true);
  };

  const createEntity = () => {
    setModalProps({
      title: 'Ajouter une entité',
      placeholder: "Nom de l'entité",
      onHide: () => setShowPromptModal(false),
      isPrompt: true,
      submitButton: {
        text: 'Ajouter',
        variant: 'success',
        submit: async (input) => {
          const { data } = await createRequest({ name: input });
          console.log('data', data);
          await updateMotherRequest({ children: [data._id] });
          refetch();
        }
      },
      cancelButton: {
        text: 'Annuler',
        variant: 'secondary'
      }
    });
    setShowPromptModal(true);
  };

  const updateEntity = () => {
    setModalProps({
      title: 'Modifier une entité',
      defaultValue: motherEntity.name,
      placeholder: motherEntity.name,
      onHide: () => setShowPromptModal(false),
      isPrompt: true,
      submitButton: {
        text: 'Modifier',
        variant: 'success',
        submit: async (input) => {
          await updateRequest({ name: input }, URLS.MOTHERENTITY.UPDATE(motherEntity._id));
          refetch();
        }
      },
      cancelButton: {
        text: 'Annuler',
        variant: 'secondary'
      }
    });
    setShowPromptModal(true);
  };

  return (
    <>
      <CustomModal show={showPromptModal} onHide={setShowPromptModal} {...modalProps}>
        {!modalProps.isPrompt ? 'Etes vous sûre de vouloir supprimer cette entité ?' : ''}
      </CustomModal>
      <li key={motherEntity._id} data-cy="entities-child">
        <span>
          {motherEntity.name}
          <div data-cy="entities-admin-action-btn" style={{ display: 'inline' }}>
            <FaTrash
              className="actionButton deleteButton"
              onClick={deleteEntity}
              data-cy="entities-admin-delete-child-btn"
            />
            <FaPen
              className="actionButton deleteButton"
              onClick={updateEntity}
              data-cy="entities-admin-update-child-btn"
            />
            <FaPlus
              className="actionButton addButton"
              onClick={createEntity}
              data-cy="entities-admin-add-child-btn"
            />
          </div>
        </span>
        <ul>
          {motherEntity.children &&
            motherEntity.children.map((entity) => (
              <Tree key={entity._id} motherEntity={entity} refetch={refetch} />
            ))}
        </ul>
      </li>
    </>
  );
};

Tree.propTypes = {
  motherEntity: PropTypes.any.isRequired,
  refetch: PropTypes.func.isRequired
};

const MotherEntitiesViewer = () => {
  const [showPromptModal, setShowPromptModal] = useState(false);
  const { request, data: motherEntities, loading } = useRequest(URLS.MOTHERENTITY.GETALL());
  const { request: createEntityRequest } = useRequest(URLS.MOTHERENTITY.CREATE());

  useEffect(() => {
    request();
  }, []);

  if (loading) return <Spinner />;

  return (
    <>
      <CustomModal
        title="Ajouter une entité"
        placeholder="Nom de l'entité"
        show={showPromptModal}
        onHide={() => setShowPromptModal(false)}
        cancelButton={{
          text: 'Annuler',
          variant: 'secondary'
        }}
        submitButton={{
          text: 'Ajouter',
          variant: 'success',
          submit: (input) => {
            createEntityRequest({ name: input }).then(({ data }) => {
              motherEntities.push(data);
            });
          }
        }}
        isPrompt
      />
      <BreadcrumbL
        links={[
          ['/', 'Accueil'],
          ['/entities', 'Entités']
        ]}
      />
      <Row>
        <h3>Liste des entités</h3>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <Card.Title></Card.Title>
            <Card.Subtitle className="text-muted"></Card.Subtitle>
            <Row>
              <Col>
                <div
                  style={{
                    float: 'right'
                  }}
                  onClick={() => setShowPromptModal(true)}
                  data-cy="entities-admin-add-btn"
                  className="addButton">
                  Ajouter une entité
                  <FaPlus
                    style={{
                      paddingLeft: '10px',
                      marginRight: '10px',
                      width: '25px',
                      height: '25px',
                      color: 'grey'
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <div data-cy="entities-viewer">
                {motherEntities.map((motherEntity) => (
                  <ul className="wtree" key={motherEntity._id} data-cy="entities-parent">
                    <Tree motherEntity={motherEntity} refetch={request} />
                  </ul>
                ))}
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default MotherEntitiesViewer;
