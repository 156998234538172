import React, { useState, useEffect } from 'react';
import { Row, Card } from 'react-bootstrap';
import Spinner from 'src/components/Spinner/Spinner';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import NestedFilterButtons from '../List/NestedFilterButtons';
// import { SearchUser } from './SearchUser';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import SearchUser from './SearchUser';

const UserList = () => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const {
    data: allUsers,
    request: usersRequest,
    loading: loadingUsers
  } = useRequest(URLS.USER.GETALL());
  const { request: motherEntitiesRequest, loading: loadingMotherEntities } = useRequest(
    URLS.MOTHERENTITY.GETALL()
  );
  const {
    data: userEntities,
    loading: loadingUserEntities,
    request: userEntitiesRequest
  } = useRequest(URLS.USERENTITY.GETALL());

  const fetchUsers = () => {
    usersRequest().then(({ data }) => {
      const filteredUsers = data.filter((user) =>
        userEntities.find((entity) => entity.user_id._id === user._id)
      );
      setFilteredUsers(filteredUsers);
    });
  };
  useEffect(() => {
    userEntitiesRequest().then(({ data: userEntities }) => {
      usersRequest().then(({ data: allUsers }) => {
        const filteredUsers = allUsers.filter((user) =>
          userEntities.find((entity) => entity.user_id._id === user._id)
        );
        setFilteredUsers(filteredUsers);
      });
    });
    motherEntitiesRequest().then(({ data: motherEntities }) => {
      const getCheckboxStates = (motherEntities) =>
        motherEntities.map((motherEntity) => ({
          id: motherEntity._id,
          label: motherEntity.name,
          children: getCheckboxStates(motherEntity.children)
        }));
      setCheckboxes(getCheckboxStates(motherEntities));
    });
  }, []);

  const handleCheckboxChange = (selectedMotherEntities) => {
    const selectedMotherEntitiesIDs = selectedMotherEntities.map((motherEntity) => motherEntity.id);
    const filteredEntities = userEntities.filter((entity) =>
      selectedMotherEntitiesIDs.includes(entity.motherEntity_id?._id)
    );
    const filteredUsers = allUsers.filter((user) =>
      filteredEntities.find((entity) => entity.user_id._id === user._id)
    );
    setFilteredUsers(filteredUsers);
  };

  if (loadingUsers || loadingMotherEntities || loadingUserEntities) return <Spinner />;

  return (
    <div>
      <BreadcrumbL
        links={[
          ['/', 'Accueil'],
          ['/users', 'Users']
        ]}
      />
      <Row>
        <h3>Liste des users</h3>
      </Row>
      <Row style={{ marginBottom: 10, marginTop: 5 }} data-cy="list-entity">
        {checkboxes && checkboxes.length > 0 && (
          <NestedFilterButtons items={checkboxes} onChange={handleCheckboxChange} />
        )}
      </Row>
      <Row>
        <Card>
          <Card.Body data-cy="list-search-user" className="smartList">
            <SearchUser fetchUsers={fetchUsers} data={filteredUsers} />
          </Card.Body>
        </Card>
      </Row>
    </div>
  );
};

export default UserList;
