// Create a prompt modal component
//
// This component is a modal that asks the user for a string input.
// It is used to ask the user for a name when creating a new entity.

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CustomModal = ({
  show,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onHide = () => {},
  isPrompt = true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submitButton = { text: 'Submit', variant: 'primary', submit: () => {} },
  cancelButton = { text: 'Cancel', variant: 'secondary' },
  title = '',
  placeholder = '',
  children = [],
  defaultValue = ''
}) => {
  const [input, setInput] = useState(defaultValue);

  const handleSubmit = (event) => {
    event.preventDefault();
    submitButton.submit(input);
    onHide();
    setInput('');
  };

  const handleOnHide = () => {
    onHide();
    setInput('');
  };

  return (
    <Modal show={show} onHide={handleOnHide} data-cy="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {isPrompt && (
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasic">
              {children}
              <Form.Control
                type="text"
                placeholder={placeholder}
                value={input || defaultValue}
                onChange={(event) => setInput(event.target.value)}
                data-cy="custom-modal-input"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
      )}
      {!isPrompt && children && <Modal.Body>{children}</Modal.Body>}
      <Modal.Footer>
        {cancelButton && cancelButton.text && (
          <Button
            variant={cancelButton ?? 'secondary'}
            onClick={handleOnHide}
            data-cy="custom-modal-secondary-btn">
            {cancelButton.text}
          </Button>
        )}
        <Button
          variant={submitButton.variant ?? 'primary'}
          onClick={handleSubmit}
          data-cy="custom-modal-submit-btn">
          {submitButton.text}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CustomModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  isPrompt: PropTypes.bool,
  defaultValue: PropTypes.string,
  submitButton: PropTypes.shape({
    variant: PropTypes.string,
    text: PropTypes.string.isRequired,
    submit: PropTypes.func
  }),
  cancelButton: PropTypes.shape({
    variant: PropTypes.string,
    text: PropTypes.string.isRequired,
    submit: PropTypes.func
  }),
  title: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node
};

export default CustomModal;
