import React, { createContext, useState, useEffect } from 'react';

// Créer un contexte pour le thème
const ThemeContext = createContext();

// Créer un composant de contexte fournissant le contexte du thème
const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [family, setFamily] = useState(localStorage.getItem('font_family') || 'classic');
  const [size, setSize] = useState(localStorage.getItem('font_size') || '100');

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (theme === 'dark') {
      htmlElement.classList.add('dark-mode');
      htmlElement.classList.remove('light-mode');
    } else {
      htmlElement.classList.add('light-mode');
      htmlElement.classList.remove('dark-mode');
    }

    if (family === 'classic') {
      htmlElement.classList.add('classic-family');
      htmlElement.classList.remove('handwriting-family');
    } else {
      htmlElement.classList.add('handwriting-family');
      htmlElement.classList.remove('classic-family');
    }

    if (size === '100') {
      htmlElement.classList.add('fontStyle-100');
      htmlElement.classList.remove('fontStyle-110');
    } else {
      htmlElement.classList.add('fontStyle-110');
      htmlElement.classList.remove('fontStyle-100');
    }
  }, [theme, family, size]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light');
  };

  const toggleFamily = () => {
    setFamily((prevFamily) => (prevFamily === 'classic' ? 'handwriting' : 'classic'));
    localStorage.setItem('font_family', family === 'classic' ? 'handwriting' : 'classic');
  };

  const toggleSize = () => {
    setSize((prevSize) => (prevSize === '100' ? '110' : '100'));
    localStorage.setItem('font_size', size === '100' ? '110' : '100');
  };
  return (
    <ThemeContext.Provider
      value={{
        theme, // Assurez-vous que la valeur theme est passée dans le contexte
        toggleTheme,
        family,
        toggleFamily,
        size,
        toggleSize
      }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
