import React from 'react';
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';

const formatDate = (date) => {
  const d = new Date(date);
  const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  const seconds = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  return `${hours}:${minutes}:${seconds} (${day}/${month})`;
};

const columns = [
  {
    id: 1,
    name: 'Temperature',
    cell: (row) => Number(Number(row.temperature).toFixed(1)),
    selector: (row) => row.temperature,
    sortable: true,
    reorder: true
  },
  {
    id: 2,
    name: 'Pulse Rate',
    cell: (row) => Number(Number(row.pulse_rate).toFixed(0)),
    selector: (row) => row.pulse_rate,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: 'Spo2',
    cell: (row) => Number(Number(row.spo2).toFixed(2)),
    selector: (row) => row.spo2,
    sortable: true,
    reorder: true
  },
  {
    id: 4,
    name: 'Date',
    cell: (row) => formatDate(row.date),
    selector: (row) => row.date,
    sortable: true,
    reorder: true
  }
];

const constantAsGraph = (constants) => {
  const data = [];
  const temperatures = constants.temperature;
  const pulseRates = constants.pulse_rate;
  const spo2s = constants.spo2;
  const dates = constants.date;

  for (let i = dates.length - 1; i >= 0; i--) {
    data.push({
      temperature: temperatures[i],
      pulse_rate: pulseRates[i],
      spo2: spo2s[i],
      date: dates[i]
    });
  }
  return data;
};

const RawConstants = ({ constants }) => (
  <div data-cy="cdv-data-table">
    <DataTable columns={columns} data={constantAsGraph(constants)} pagination />
  </div>
);

RawConstants.propTypes = {
  constants: PropTypes.object.isRequired
};

export default RawConstants;
