import React, { useEffect, useState } from 'react';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import { Row, Col, FloatingLabel, Form, Button, Spinner } from 'react-bootstrap';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROLE } from 'src/Enums';

const ModifyUser = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role: ROLE.USER
  });
  const [searchParams] = useSearchParams();
  const { request: createLink } = useRequest(URLS.USERENTITY.CREATE());
  const { request } = useRequest(URLS.USER.UPDATE(searchParams.get('id')));
  const { request: userRequest, loading: loadingUser } = useRequest(
    URLS.USER.GET(searchParams.get('id'))
  );
  const [motherEntity_id, setMotherEntity_id] = useState('');
  const { request: userEntityRequest } = useRequest(URLS.USERENTITY.GETALL());

  const {
    request: getMotherEntityRequest,
    data: motherEntities,
    loading
  } = useRequest(URLS.MOTHERENTITY.GETALL());

  const handleSubmit = (event) => {
    event.preventDefault();
    request(userData).then(({ data }) => {
      createLink({
        user_id: data._id,
        motherEntity_id: motherEntity_id
      }).then(() => navigate('/users'));
    });
  };

  useEffect(() => {
    userRequest().then(({ data: userD }) => {
      setUserData({
        name: userD.name,
        last_name: userD.last_name,
        email: userD.email,
        phone_number: userD.phone_number,
        role: userD.role
      });
      userEntityRequest().then(({ data: userEntityD }) => {
        const userEntity = userEntityD.findLast(
          (userEntity) => userEntity.user_id._id === userD._id
        );
        if (userEntity) {
          setMotherEntity_id(userEntity.motherEntity_id._id);
          getMotherEntityRequest();
        }
      });
    });
  }, []);

  const renderChildren = (children, depth) => {
    return children.map((child) => (
      <>
        {child.children.length ? (
          <>
            <option value={child._id} key={child._id}>
              {new Array(depth * 4).forEach(() => '&nbsp;')}
              {child.name}
            </option>
            {renderChildren(child.children, depth + 1)}
          </>
        ) : (
          <option key={child._id} value={child._id}>
            &nbsp;&nbsp;&nbsp;&nbsp;{child.name}
          </option>
        )}
      </>
    ));
  };

  if (loadingUser) return <Spinner />;

  return (
    <div>
      <BreadcrumbL
        links={[
          ['/', 'Accueil'],
          ['/users', 'Utilisateurs'],
          [`/modify_user?id=${searchParams.get('id')}`, `${userData.name} ${userData.last_name}`]
        ]}
      />
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center align-items-center" xs={10}>
          <Col md={5}>
            <h3 className="mb-5 text-center font-bold">
              Formulaire de modification d'un utilisateur
            </h3>
            <Row className="mb-4">
              <h4>Informations de l'utilisateur</h4>
            </Row>

            <Row>
              <Col xs={6}>
                <Form.Group>
                  <FloatingLabel controlId="floatingFirstName" label="Prénom">
                    <Form.Control
                      required
                      type="text"
                      placeholder=" "
                      value={userData.name}
                      onChange={(event) => {
                        setUserData({ ...userData, name: event.target.value });
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group>
                  <FloatingLabel controlId="floatinglast_name" label="Nom">
                    <Form.Control
                      required
                      type="text"
                      placeholder=" "
                      value={userData.last_name}
                      onChange={(event) => {
                        setUserData({ ...userData, last_name: event.target.value });
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <Form.Group>
                  <FloatingLabel controlId="floatingEmail" label="Email">
                    <Form.Control
                      required
                      type="text"
                      placeholder=" "
                      value={userData.email}
                      onChange={(event) => {
                        setUserData({ ...userData, email: event.target.value });
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <FloatingLabel controlId="floatingPhoneNumber" label="Numéro">
                    <Form.Control
                      required
                      type="text"
                      placeholder=" "
                      value={userData.phone_number}
                      onChange={(event) => {
                        setUserData({ ...userData, phone_number: event.target.value });
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row xs={12} className="mb-3">
              <Col>
                <Form.Group>
                  <FloatingLabel controlId="floatingSelect" label="Select one">
                    {loading || !motherEntities || !motherEntities.length ? (
                      <Spinner />
                    ) : (
                      <Form.Select
                        aria-label="Floating label select example"
                        required
                        defaultValue={motherEntity_id}
                        onChange={(event) => {
                          setMotherEntity_id(event.target.value);
                        }}>
                        {motherEntities.map((motherEntity) => (
                          <>
                            <option value={motherEntity._id} key={motherEntity._id}>
                              {motherEntity.name}
                            </option>
                            {renderChildren(motherEntity.children, 0)}
                          </>
                        ))}
                      </Form.Select>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <FloatingLabel controlId="floatingSelect" label="Select one">
                    <Form.Select
                      aria-label="Floating label select example"
                      required
                      defaultValue={userData.role}
                      onChange={(event) => {
                        setUserData({ ...userData, role: event.target.value });
                      }}>
                      <option value={ROLE.USER}>Utilisateur</option>
                      <option value={ROLE.ADMIN}>Administrateur</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} className="">
                <Button type="submit">Modifier un utilisateur</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ModifyUser;
