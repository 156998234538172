import { React, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import CustomModal from '../Modals/CustomModal';

const DeleteButtonUser = ({ onDeleteClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <CustomModal
        title="Supprimer un utilisateur"
        onHide={() => setIsModalOpen(false)}
        isPrompt={false}
        submitButton={{
          text: 'Supprimer',
          variant: 'danger',
          submit: () => {
            onDeleteClick();
          }
        }}
        cancelButton={{
          text: 'Annuler',
          variant: 'secondary'
        }}
        show={isModalOpen}>
        Etes vous sûre de vouloir supprimer cet utilisateur ?
      </CustomModal>
      <div>
        <IconButton onClick={() => setIsModalOpen(true)}>
          <Delete color="error" />
        </IconButton>
      </div>
    </>
  );
};

export default DeleteButtonUser;
