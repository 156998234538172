import Graph from './Graph/Graph';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Spinner from 'src/components/Spinner/Spinner';
import { Row, Col, Card, Table } from 'react-bootstrap';
import RawConstants from 'src/components/CDV/RawConstants/RawConstants';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import { STATUS } from 'src/Enums';

const DemoCDV = () => {
  const [searchParams] = useSearchParams();

  const {
    request,
    data: patientInfos,
    loading
  } = useRequest(URLS.PATIENT.GET_DEMO(searchParams.get('id')));

  const [constants, setConstants] = useState({
    temperature: [],
    pulse_rate: [],
    spo2: [],
    date: []
  });

  useEffect(() => {
    request().then(({ data }) => {
      console.log(data);
      setConstants(data.constants);
    });
  }, []);

  if (loading) return <Spinner />;

  const statusColor = {
    [STATUS.STABLE]: 'text-green',
    [STATUS.UNSTABLE]: 'text-red',
    [STATUS.UNKNOWN]: 'text-normal'
  };

  return (
    // <div>
    <Row style={{ width: 'calc(100vw - 64px - 40px)' }}>
      <Col xs={12} className="my-1 mb-3">
        <h3>Informations du patient</h3>
        <Card>
          <Card.Body>
            <Row>
              <Col xs={12} lg={6}>
                <Table className="table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td className="" style={{ width: '10%' }}>
                        <span className="text-muted">Nom</span>
                      </td>
                      <td>
                        {patientInfos.name} {patientInfos.last_name}
                      </td>
                    </tr>
                    <tr>
                      <td className="">
                        <span className="text-muted">Pathologie</span>
                      </td>
                      <td>{patientInfos.pathology}</td>
                    </tr>
                    <tr>
                      <td className="">
                        <span className="text-muted">Statut</span>
                      </td>
                      <td>
                        <span
                          className={statusColor['Stable']}
                          style={{
                            fontWeight: 'bold'
                          }}>
                          Stable
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col xs={12} lg={6}>
                <Table className="table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td style={{ width: '10%' }}>
                        <span className="text-muted">Téléphone</span>
                      </td>
                      <td>
                        {patientInfos.phone_number ? patientInfos.phone_number : 'non renseigné'}
                      </td>
                    </tr>
                    <tr>
                      <td className="">
                        <span className="text-muted">Email</span>
                      </td>
                      <td>{patientInfos.email ? patientInfos.email : 'non renseigné'}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} lg={6}>
        <h3>Données brutes</h3>
        <Card>
          <Card.Body>
            <div className="smartList" data-cy="cdv-rawconstants">
              {constants.date.length === 0 ? (
                <span>Pas de données</span>
              ) : (
                <RawConstants constants={constants} />
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} lg={6}>
        <h3>Données graphiques</h3>
        <Card>
          <Card.Body data-cy="cdv-graph">
            {constants.date.length === 0 ? (
              <span>Pas de données</span>
            ) : (
              <Graph constants={constants} />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
    // </div>
  );
};

export default DemoCDV;
