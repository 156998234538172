import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import envURL from 'src/Env';
import { IURL } from 'src/globals';

type THeader = { [key: string]: string };

interface IRequestResponse {
  data: unknown | null;
  response: Response | null;
}

const useRequest = (urlObj: IURL, options: RequestInit = {}) => {
  const [data, setData] = useState<unknown | null>(null);
  const [response, setResponse] = useState<Response | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const request = async (
    body: BodyInit | null = null,
    newUrlObj: null | IURL = null
  ): Promise<IRequestResponse> => {
    setLoading(true);
    let returnVal: IRequestResponse = { data: null, response: null };
    try {
      urlObj = newUrlObj ? newUrlObj : urlObj;
      options.method = urlObj.method;
      if (body) options.body = body;
      if (options.body) options.body = JSON.stringify(options.body);
      if (!options.headers) options.headers = {};
      (options.headers as THeader)['Content-Type'] = 'application/json';
      const token = localStorage.getItem('userToken');
      if (token) (options.headers as THeader).Authorization = `Bearer ${JSON.parse(token)}`;
      console.log('route', `${envURL}${urlObj.route}`);
      console.log('options', options);
      const response = await fetch(`${envURL}${urlObj.route}`, options);
      setResponse(response);
      returnVal = { response, data: null };
      if (response.status === 204) return returnVal;
      const data = await response.json();
      setData(data);
      returnVal = { data, response };
    } catch (err) {
      console.log('err', err);
      returnVal = { data: null, response: null };
    } finally {
      console.log('HERE =>>>>', returnVal);
      if (
        returnVal.response &&
        (returnVal.response?.status === 401 || returnVal.response.status === 403)
      ) {
        localStorage.removeItem('userToken');
        navigate('/login');
      } else if (returnVal.response && returnVal.response.status >= 400) {
        console.log('returnVal', returnVal);
        navigate('/home');
      }
      console.log('returnVal', returnVal);
      setLoading(false);
    }
    return returnVal;
  };

  return { data, response, loading, request };
};

export default useRequest;
