import React from 'react';
import { Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { FiletypeCsv, FiletypeXlsx, FiletypeJson } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import { utils, write } from 'xlsx';
import Blob from 'blob';

function ButtonExportConstants({ patient }) {
  function downloadFileAsXLSX() {
    const constants = patient.constants ?? [];
    const workbook = utils.book_new();
    const newConstants = [];
    for (let i = 0; i < constants.date.length; i++) {
      const date = new Date(constants.date[i]);
      newConstants.push({
        temperature: constants.temperature[i],
        pulse_rate: constants.pulse_rate[i],
        spo2: constants.spo2[i],
        date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
      });
    }
    const worksheet = utils.json_to_sheet(newConstants);
    utils.book_append_sheet(workbook, worksheet, 'Contants');
    const fileBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([fileBuffer], { type: 'application/vnd.ms-excel' });
    saveAs(blob, `${patient.name}_${patient.last_name}_constants.xlsx`);
  }

  const downloadFileAsJSON = () => {
    const blob = new Blob([JSON.stringify(patient.constants)], { type: 'application/json' });
    saveAs(blob, `${patient.name}_${patient.last_name}_constants.json`);
  };

  const downloadFileAsCSV = () => {
    const temperatures = patient.constants.temperature ?? [];
    const pulse_rates = patient.constants.pulse_rate ?? [];
    const spo2s = patient.constants.spo2 ?? [];
    const dates = patient.constants.date ?? [];
    const constants = dates.map((date, index) => ({
      temperature: temperatures[index],
      pulse_rate: pulse_rates[index],
      spo2: spo2s[index],
      date
    }));

    const csv = constants
      .map((constant) => {
        const date = new Date(constant.date);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()};${
          constant.temperature
        };${constant.pulse_rate};${constant.spo2}`;
      })
      .join('\n');
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, `${patient.name}_${patient.last_name}_constants.csv`);
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => downloadFileAsJSON()}
        data-cy="json-export-btn"
        className="my-2">
        <FiletypeJson />
        Télécharger les constantes (JSON)
      </Button>
      <br />
      <Button
        variant="primary"
        onClick={() => downloadFileAsCSV()}
        data-cy="cdv-export-btn"
        className="my-2">
        <FiletypeCsv />
        Télécharger les constantes (CSV)
      </Button>
      <br />
      <Button variant="primary" onClick={() => downloadFileAsXLSX()} className="my-2">
        <FiletypeXlsx />
        Télécharger les constantes (XLSX)
      </Button>
    </>
  );
}

ButtonExportConstants.propTypes = {
  patient: PropTypes.shape({
    name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    constants: PropTypes.shape({
      temperature: PropTypes.arrayOf(PropTypes.number).isRequired,
      pulse_rate: PropTypes.arrayOf(PropTypes.number).isRequired,
      spo2: PropTypes.arrayOf(PropTypes.number).isRequired,
      date: PropTypes.arrayOf(PropTypes.string).isRequired
    }).isRequired
  }).isRequired
};

export default ButtonExportConstants;
