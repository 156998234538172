import './Login.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CloudsMp4 from 'src/assets/login/cc.mp4';
import Clouds from 'src/assets/login/clouds.png';
import CloudsWebm from 'src/assets/login/cc.webm';
import Logo from 'src/assets/images/livome_min_white.png';
import { Card, FloatingLabel, Image } from 'react-bootstrap';
import useCurrentUser from '../../hooks/useCurrentUser';
import Spinner from '../Spinner/Spinner';
import CustomModal from '../Modals/CustomModal';

import Forgot from './Forgot';

import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';

const Login = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const { loading, login } = useCurrentUser();
  const [invalidIdentifiers, setInvalidIdentifiers] = useState(false);
  const { request: requestLogin } = useRequest(URLS.AUTH.LOGIN(), {
    body: { email, password }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    requestLogin().then(({ data, response }) => {
      if (!response || response.status !== 200) return setInvalidIdentifiers(true);
      if (data && data.token) login(data.token);
    });
  };

  if (loading) return <Spinner />;

  return (
    <div>
      <CustomModal
        show={invalidIdentifiers}
        onHide={() => setInvalidIdentifiers(false)}
        title="Identifiants invalides"
        isPrompt={false}
        submitButton={{
          variant: 'secondary',
          text: 'Fermer',
          submit: () => {
            setInvalidIdentifiers(false);
          }
        }}>
        <span>Les identifiants que vous avez saisis sont invalides.</span>
      </CustomModal>
      <div className="clouds">
        <video poster={Clouds} id="bgvid" playsInline autoPlay muted loop>
          <source src={CloudsWebm} type="video/webm" />
          <source src={CloudsMp4} type="video/mp4" />
        </video>
      </div>
      <div className="cardDiv">
        <Row>
          <Col xs={12} md={4}>
            <Card className="cardForm">
              <Card.Header className="cardHeader">
                <Row className="py-2">
                  <Col xs={12} className="cardTitle">
                    <Image src={Logo} alt="Logo" className="logo mb-1" />
                    <span className="text-white ms-3 login-title">Livome</span>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Form noValidate onSubmit={handleSubmit}>
                  <Row>
                    <Form.Group as={Col} md="12" className="m-0" data-cy="login-form">
                      <FloatingLabel controlId="floatingEmail" label="Adresse mail" className="">
                        <Form.Control
                          required
                          type="text"
                          placeholder=" "
                          className="rounded-0 rounded-top"
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                          data-cy="email-input"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="Mot de passe"
                        className="mb-3">
                        <Form.Control
                          required
                          type="password"
                          placeholder=" "
                          className="rounded-0 rounded-bottom"
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          data-cy="password-input"
                        />
                        <Form.Control.Feedback type="valid">Validé !</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Veuillez entrer votre mot de passe.
                          <br />
                          <br />
                          <a href="">Mot de passe oublié</a>
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      <Button
                        type="submit"
                        className="btn btn-block w-100 bg-blue-primary"
                        data-cy="login-button">
                        Connexion
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Forgot />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
