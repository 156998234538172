import React from 'react';
import './NotFoundPage.css';

const NotFoundPage = () => (
  <div className="container" data-cy="">
    <h1 className="title">404 Not Found</h1>
    <p className="message">La page que vous cherchez n'a pas été trouvée.</p>
  </div>
);

export default NotFoundPage;
