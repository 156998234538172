import { useLocation } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PropTypes from 'prop-types';

function BreadcrumbL({ links }) {
  const currentLocation = useLocation().pathname;
  return (
    <Breadcrumb className="breadcrumb my-0">
      {links.map((link, index) => (
        <Breadcrumb.Item
          key={index}
          href={link[0]}
          active={currentLocation == link[0] ? true : false}>
          {link[1]}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

BreadcrumbL.propTypes = {
  links: PropTypes.array.isRequired
};

export default BreadcrumbL;
