import HeaderBar from './HeaderBar';
import SideNavBar from './SideNavBar';

export default function Menu() {
  return (
    <div>
      <HeaderBar />
      <SideNavBar />
    </div>
  );
}
