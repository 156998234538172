import { useState, useEffect, useContext } from 'react';
import { URLS } from './../globals';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContextProvider';
import useRequest from './useRequest';

const useCurrentUser = () => {
  const { user, setUser } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { data, loading: loadingUser, request: requestUser } = useRequest(URLS.AUTH.ME());

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) return logout();
    if (user) return setLoading(false);
    requestUser().then(({ response }) => {
      if (!response || response.status !== 200) return logout();
    });
  }, []);

  useEffect(() => {
    if (loadingUser) return;
    if (data) {
      setUser(data);
      setLoading(false);
    }
  }, [loadingUser]);

  const login = async (token: string) => {
    setLoading(true);
    localStorage.setItem('userToken', JSON.stringify(token));
    const { response } = await requestUser();
    if (!response || response.status !== 200) return logout();
    navigate('/');
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    setUser(null);
    setLoading(false);
    navigate('/login');
  };

  return { user, loading, login, logout };
};

export default useCurrentUser;
