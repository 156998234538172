export const URLS = {
  AUTH: {
    LOGIN: (): IURL => ({ route: '/auth/login', method: 'POST' }),
    ME: (): IURL => ({ route: '/auth/me', method: 'GET' }),
    REGISTER: (): IURL => ({ route: '/auth/register', method: 'POST' }),
    FORGOT: (): IURL => ({ route: '/auth/forgot', method: 'POST' })
  },
  USER: {
    GETALL: (): IURL => ({ route: `/user`, method: 'GET' }),
    GET: (id: string): IURL => ({ route: `/user/${id}`, method: 'GET' }),
    CREATE: (): IURL => ({ route: `/user`, method: 'POST' }),
    UPDATE: (id: string): IURL => ({ route: `/user/${id}`, method: 'PUT' }),
    DELETE: (id: string): IURL => ({ route: `/user/${id}`, method: 'DELETE' })
  },
  PATIENT: {
    GETALL: (): IURL => ({ route: `/patient`, method: 'GET' }),
    GET: (id: string): IURL => ({ route: `/patient/${id}`, method: 'GET' }),
    CREATE: (): IURL => ({ route: `/patient`, method: 'POST' }),
    UPDATE: (id: string): IURL => ({ route: `/patient/${id}`, method: 'PUT' }),
    DELETE: (id: string): IURL => ({ route: `/patient/${id}`, method: 'DELETE' }),
    GET_DEMO: (id: string): IURL => ({ route: `/patient_demo/${id}`, method: 'GET' })
  },
  MOTHERENTITY: {
    GETALL: (): IURL => ({ route: `/motherentity`, method: 'GET' }),
    GET: (id: string): IURL => ({ route: `/motherentity/${id}`, method: 'GET' }),
    CREATE: (): IURL => ({ route: `/motherentity`, method: 'POST' }),
    UPDATE: (id: string): IURL => ({ route: `/motherentity/${id}`, method: 'PUT' }),
    DELETE: (id: string): IURL => ({ route: `/motherentity/${id}`, method: 'DELETE' })
  },
  USERENTITY: {
    GETALL: (): IURL => ({ route: `/userentity`, method: 'GET' }),
    GET: (id: string): IURL => ({ route: `/userentity/${id}`, method: 'GET' }),
    CREATE: (): IURL => ({ route: `/userentity`, method: 'POST' }),
    UPDATE: (id: string): IURL => ({ route: `/userentity/${id}`, method: 'PUT' }),
    DELETE: (id: string): IURL => ({ route: `/userentity/${id}`, method: 'DELETE' })
  },
  MAIL: {
    SEND_FROM_USER_TO_STAFF: (): IURL => ({ route: `/mail/user/livome`, method: 'POST' }),
    SEND_TO_PATIENT: (): IURL => ({ route: `/mail/user/patient`, method: 'POST' })
  },
  IMAGE: {
    GET: (): IURL => ({ route: `/image`, method: 'GET' }),
    POST: (): IURL => ({ route: `/image`, method: 'POST' })
  }
} as const;

export interface IURL {
  route: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
}

export const DATABASE_MODELS = {
  USER: 'users',
  PATIENT: 'patients',
  MOTHERENTITY: 'motherentities',
  USERENTITY: 'userentities',
  USERSIMULATION: 'usersimulations'
} as const;

export const ALERT_MODES = [
  { value: 'sms', label: 'sms' },
  { value: 'mail', label: 'mail' }
];

export type DatabaseModels = (typeof DATABASE_MODELS)[keyof typeof DATABASE_MODELS];
