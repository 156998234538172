import React from 'react';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import CustomModal from '../Modals/CustomModal';
import useRequest from 'src/hooks/useRequest';
import PropTypes from 'prop-types';
import { URLS } from 'src/globals';

const DeleteButton = ({ patient, onDeleteClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { request } = useRequest(URLS.PATIENT.DELETE(patient._id));

  return (
    <>
      <CustomModal
        title="Supprimer un patient"
        onHide={() => setIsModalOpen(false)}
        isPrompt={false}
        submitButton={{
          text: 'Supprimer',
          variant: 'danger',
          submit: () =>
            request().then(() => {
              setIsModalOpen(false);
              onDeleteClick();
            })
        }}
        cancelButton={{
          text: 'Annuler',
          variant: 'secondary'
        }}
        show={isModalOpen}>
        Etes vous sûre de vouloir supprimer ce patient ?
      </CustomModal>
      <div data-cy="list-search-delete">
        <IconButton
          aria-label="delete"
          className="text-secondary"
          onClick={() => setIsModalOpen(true)}>
          <Delete />
        </IconButton>
      </div>
    </>
  );
};

DeleteButton.propTypes = {
  patient: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default DeleteButton;
