import './Graph.css';
import Dygraph from 'dygraphs';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Checkbox from './Checkbox';
import PropTypes from 'prop-types';

const formatDate = (date) => {
  const d = new Date(date);
  const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  const seconds = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  return `${hours}:${minutes}:${seconds} (${day}/${month})`;
};

const Graph = ({ constants }) => {
  const [graph, setGraph] = useState(null);
  const [isInit, setInit] = useState(false);

  const [showTemperature, setShowTemperature] = useState(true);
  const [showPulseRate, setShowPulseRate] = useState(true);
  const [showSpo2, setShowSpo2] = useState(true);

  const getGraph = (matrix) => {
    setGraph(
      new Dygraph('graph', matrix, {
        strokeWidth: 1,
        legend: 'always',

        labels: ['date', 'Température (°C)', 'Rythme cardiaque (bpm)', 'Spo2 (%)'],
        animatedZooms: true,
        visibility: [showTemperature, showPulseRate, showSpo2],
        colors: ['#DC3545', '#28A745', '#0652B5'],
        dateWindow: [
          Math.max(new Date() - 1000 * 60 * 60 * 6, matrix[0][0]),
          Math.min(new Date(), matrix[matrix.length - 1][0])
        ],
        legendFormatter: (data) => {
          if (data.i === null) {
            let text = '';
            for (const serie of data.series) {
              if (serie.isVisible) {
                text += `<div class="dygraph-legend-line" style="color: ${serie.color};">${serie.label}</div>`;
              }
            }
            return text;
          }
          let text = formatDate(data.x);
          const metrics = ['°C', 'bpm', '%'];
          data.series.forEach((serie, i) => {
            if (serie.isVisible) {
              text += `<div class="dygraph-legend-line" style="color: ${serie.color};">${serie.yHTML} ${metrics[i]}</div>`;
            }
          });
          return text;
        },
        axes: {
          x: {
            axisLabelWidth: 80,
            axisLabelFormatter: (date) => {
              return formatDate(date);
            }
          },
          y: {
            axisLabelWidth: 60
          }
        }
      })
    );
    setInit(true);
  };

  const updateGraph = (matrix) => {
    const dateWindow = graph.xAxisRange();
    const endOfMatrix = matrix[matrix.length - 1][0];
    const diff = endOfMatrix.getTime() - dateWindow[1];
    if (diff < 300_000) {
      dateWindow[1] = endOfMatrix.getTime();
    }
    graph.updateOptions({
      file: matrix,
      strokeWidth: 1,
      legend: 'always',

      labels: ['date', 'Température (°C)', 'Rythme cardiaque (bpm)', 'Spo2 (%)'],
      animatedZooms: true,
      visibility: [showTemperature, showPulseRate, showSpo2],
      colors: ['#DC3545', '#28A745', '#0652B5'],
      dateWindow,
      legendFormatter: (data) => {
        if (data.i === null) {
          let text = '';
          for (const serie of data.series) {
            if (serie.isVisible) {
              text += `<div class="dygraph-legend-line" style="color: ${serie.color};">${serie.label}</div>`;
            }
          }
          return text;
        }
        let text = formatDate(data.x);
        const metrics = ['°C', 'bpm', '%'];
        data.series.forEach((serie, i) => {
          if (serie.isVisible) {
            text += `<div class="dygraph-legend-line" style="color: ${serie.color};">${serie.yHTML} ${metrics[i]}</div>`;
          }
        });
        return text;
      },
      axes: {
        x: {
          axisLabelWidth: 80,
          axisLabelFormatter: (date) => {
            return formatDate(date);
          }
        },
        y: {
          axisLabelWidth: 60
        }
      }
    });
  };

  useEffect(() => {
    const matrix = [];
    const temperatures = constants.temperature;
    const pulseRates = constants.pulse_rate;
    const spo2s = constants.spo2;

    for (let i = 0; i < temperatures.length; i++) {
      matrix.push([
        new Date(constants.date[i]),
        Number(Number(temperatures[i]).toFixed(2)),
        Number(Number(pulseRates[i]).toFixed(2)),
        Number(Number(spo2s[i]).toFixed(2))
      ]);
    }

    if (!isInit) getGraph(matrix);
    else updateGraph(matrix);
  }, [constants, showTemperature, showPulseRate, showSpo2]);

  return (
    <div data-cy="cdv-data-graph">
      <div className="graph">
        <div id="graph" className="w-100" />
      </div>
      <Row style={{ marginLeft: 30, marginTop: 20 }}>
        <Col xs={4}>
          <label data-cy="cdv-graph-spo2">
            <Checkbox toggleChange={() => setShowSpo2((prev) => !prev)} />
            Spo2
          </label>
        </Col>
        <Col xs={4}>
          <label data-cy="cdv-graph-temperature">
            <Checkbox toggleChange={() => setShowTemperature((prev) => !prev)} />
            Température
          </label>
        </Col>
        <Col xs={4}>
          <label data-cy="cdv-graph-pulse_rate">
            <Checkbox toggleChange={() => setShowPulseRate((prev) => !prev)} />
            Rythme cardiaque
          </label>
        </Col>
      </Row>
    </div>
  );
};

Graph.propTypes = {
  constants: PropTypes.object.isRequired
};

export default Graph;
