import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

interface NestedFilterButtonItem {
  id: string;
  label: string;
  children?: NestedFilterButtonItem[];
}

interface NestedFilterButtonsProps {
  items: NestedFilterButtonItem[];
  onChange: (_items: NestedFilterButtonItem[]) => void;
}

const NestedFilterButtons = ({ items, onChange }: NestedFilterButtonsProps) => {
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>(() => {
    const getChildrenIds = (items: NestedFilterButtonItem[]) => {
      return items.reduce((acc, item) => {
        const childrenIds = item.children ? getChildrenIds(item.children) : [];
        return [...acc, item.id, ...childrenIds];
      }, [] as string[]);
    };
    return getChildrenIds(items);
  });

  const handleOnChange = (item: NestedFilterButtonItem) => {
    let newSelectedItems: NestedFilterButtonItem[] = [];
    const getItemsFromIds = (items: NestedFilterButtonItem[], ids: string[]) => {
      const getItems = (items: NestedFilterButtonItem[]) => {
        return items.reduce((acc, item) => {
          if (item.children) {
            const childrenItems = getItems(item.children);
            if (ids.includes(item.id)) return [...acc, ...childrenItems, item];
            if (childrenItems.length) return [...acc, ...childrenItems];
          }
          return acc;
        }, [] as NestedFilterButtonItem[]);
      };
      return getItems(items);
    };
    const getAllChildrenIds = (items: NestedFilterButtonItem[]) => {
      return items.reduce((acc, item) => {
        const childrenIds = item.children ? getAllChildrenIds(item.children) : [];
        return [...acc, ...childrenIds, item.id];
      }, [] as string[]);
    };
    if (selectedItemsIds.includes(item.id)) {
      const childrenIds = getAllChildrenIds([item]);
      const newSelectedItemsIds = selectedItemsIds.filter((id) => !childrenIds.includes(id));
      newSelectedItems = getItemsFromIds(items, newSelectedItemsIds);
      setSelectedItemsIds(newSelectedItemsIds);
    } else {
      const getParentIds = (items: NestedFilterButtonItem[], id: string) =>
        items.reduce((acc, item) => {
          if (item.id === id) return [...acc, item.id];
          if (item.children) {
            const parentIds = getParentIds(item.children, id);
            if (parentIds.length) {
              return [...acc, ...parentIds, item.id];
            }
          }
          return acc;
        }, [] as string[]);

      const parentIds = getParentIds(items, item.id);
      const childrenIds = getAllChildrenIds([item]);
      const newSelectedItemsIds = [...selectedItemsIds, ...parentIds, ...childrenIds, item.id];
      newSelectedItems = getItemsFromIds(items, newSelectedItemsIds);
      setSelectedItemsIds(newSelectedItemsIds);
    }
    onChange(newSelectedItems);
  };

  const renderItems = (items: NestedFilterButtonItem[]) => {
    if (!items.length) return null;
    return (
      <ul>
        {items.map((item) => {
          const isSelected = selectedItemsIds.includes(item.id);
          return (
            <li key={item.id} data-cy="list-entity-child">
              <Button
                variant={isSelected ? 'success' : 'secondary'}
                onClick={() => handleOnChange(item)}>
                {item.label}
              </Button>
              {item.children && renderItems(item.children)}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Row>
      {items.map((item) => {
        const isSelected = selectedItemsIds.includes(item.id);
        return (
          <Col key={item.id}>
            <ul className="wtree" data-cy="list-entity-cols">
              <li>
                <Button
                  variant={isSelected ? 'success' : 'secondary'}
                  onClick={() => handleOnChange(item)}>
                  {item.label}
                </Button>
                {item.children && renderItems(item.children)}
              </li>
            </ul>
          </Col>
        );
      })}
    </Row>
  );
};

export default NestedFilterButtons;
