import './styles/Styles.js';

import React from 'react';
import CDV from './components/CDV/CDV';
import PatientList from './components/List/PatientList';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Authentification/Login';
import TermsOfUse from './components/TermsOfUse/TermsOfUse';
import AddPatient from './components/Patient/AddPatient';
import ModifyPatient from './components/Patient/ModifyPatient';
import Support from './components/Support/SupportPage';
import Accessibility from './components/Accessibility/Accessibility';
import UserProfile from './components/User/UserProfile';
import MotherEntitiesViewer from './components/Entities/EntitiesViewer';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ShouldBeLoggedIn from './components/Authentification/RouteGuard/ShouldBeLoggedIn';
import ShouldBeLoggedOut from './components/Authentification/RouteGuard/ShouldBeLoggedOut';
import WithMenu from './components/Authentification/RouteGuard/WithMenu';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import AppContextProvider from './AppContextProvider';
import ShouldBeAdmin from './components/Authentification/RouteGuard/ShouldBeAdmin';
import UserList from './components/User/UserList';
import AddUser from './components/User/AddUser';
import ModifyUser from './components/User/ModifyUser';
import { ThemeProvider } from './components/Accessibility/ThemeContext';
import DemoCDV from './components/CDV/DemoCDV.js';

const App = () => (
  <ThemeProvider>
    <AppContextProvider>
      <Router>
        <Routes>
          <Route exact path="/login" element={<ShouldBeLoggedOut />}>
            <Route exact path="/login" element={<Login />} />
          </Route>
          <Route path="/demo_patient" element={<DemoCDV />} />
          <Route exact path="/" element={<ShouldBeLoggedIn />}>
            <Route exact path="/" element={<WithMenu />}>
              <Route exact path="/" element={<Navigate replace to="/home" />} />
              <Route path="/" element={<ShouldBeAdmin />}>
                <Route path="/users" element={<UserList />} />
                <Route path="/add_user" element={<AddUser />} />
                <Route path="/modify_user" element={<ModifyUser />} />
                <Route path="/entities" element={<MotherEntitiesViewer />} />
              </Route>
              <Route path="/home" element={<Dashboard />} />
              <Route path="/patients" element={<PatientList />} />
              <Route path="/patient" element={<CDV />} />
              <Route path="/support" element={<Support />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/terms_of_use" element={<TermsOfUse />} />
              <Route path="/add_patient" element={<AddPatient />} />
              <Route path="/modify_patient" element={<ModifyPatient />} />
              <Route path="/accessibility" element={<Accessibility />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AppContextProvider>
  </ThemeProvider>
);

export default App;
