import React from 'react';
import { Outlet } from 'react-router-dom';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { useNavigate } from 'react-router-dom';

const ShouldBeLoggedOut = () => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  return !user ? (
    <div className="flex-wrapper">
      <main className="px-4 px-md-5 py-3 py-md-4 text-primary">
        <Outlet />
      </main>
    </div>
  ) : (
    navigate('/')
  );
};

export default ShouldBeLoggedOut;
