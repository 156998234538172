import React from 'react';
import Menu from 'src/components/Menu/Menu';
import { Outlet } from 'react-router-dom';
import Footer from 'src/components/Footer/Footer';

const WithMenu = () => (
  <div className="flex-wrapper">
    <main className="px-4 px-md-5 py-3 py-md-4 text-primary">
      <Menu />
      <Outlet />
    </main>
    <Footer />
  </div>
);

export default WithMenu;
