import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { URLS } from 'src/globals';
import envURL from 'src/Env';
import useRequest from 'src/hooks/useRequest';
import CustomModal from '../Modals/CustomModal';
import useCurrentUser from 'src/hooks/useCurrentUser';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import { Row, Col, Card, FloatingLabel, Form, Button } from 'react-bootstrap';
import { ALERT_MODES } from 'src/globals';
import { PersonCircle } from 'react-bootstrap-icons';
import Image from 'react-bootstrap/Image';
import Spinner from '../Spinner/Spinner';

const CardProfile = () => {
  const [currentUrl, setCurrentUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = URLS.IMAGE.GET().route;

    fetch(`${envURL}${url}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken'))}`
      }
    }).then(async (response) => {
      if (response.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setCurrentUrl(url);
      }
      setLoading(false);
    });
  }, []);

  const imagePreview = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const f = e.target.files[0];
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
      setImageFile(f);
    };
    reader.readAsDataURL(f);
  };

  const photoUpload = (e) => {
    e.preventDefault();
    // Send file to backend
    const url = URLS.IMAGE.POST().route;
    const formData = new FormData();
    formData.append('image', imageFile);

    fetch(`${envURL}${url}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken'))}`
      }
    }).then(() => {
      setCurrentUrl(imagePreviewUrl);
      setImagePreviewUrl(null);
      setImageFile(null);
      location.reload();
    });
  };

  const cancelPhotoUpload = (e) => {
    e.preventDefault();
    setImagePreviewUrl(null);
    setImageFile(null);
  };

  return (
    <div>
      <Card>
        <Form>
          <Row xs={4} className="mb-1">
            <Col xs={4} />
            <Col xs={4}>
              {loading ? (
                <Spinner />
              ) : imagePreviewUrl || currentUrl ? (
                <Image
                  src={imagePreviewUrl || currentUrl}
                  roundedCircle={true}
                  height="200px"
                  width="200px"
                  alt="Photo de profil"
                />
              ) : (
                <PersonCircle
                  className="mx-2"
                  height="200px"
                  width="200px"
                  overflow="hidden"
                  alt="Photo de profil"
                />
              )}
            </Col>
          </Row>
          <Row xs={4}>
            <Col xs={4} />
            <Col xs={4}>
              {imagePreviewUrl ? (
                <Row>
                  <Col xs={6}>
                    <Button type="button" onClick={photoUpload}>
                      Enregistrer
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <Button type="button" variant="danger" onClick={cancelPhotoUpload}>
                      Annuler
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Button type="button" onClick={() => fileInputRef.current.click()}>
                  Modifier votre photo de profil
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/png, image/jpeg, image/jpg"
                    ref={fileInputRef}
                    onChange={imagePreview}
                  />
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

const UserProfile = () => {
  const { user } = useCurrentUser();
  const [newUser, setNewUser] = useState(user);

  const { request: requestUpdate } = useRequest(URLS.USER.UPDATE(user._id));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const nameRegex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const phoneRegex = /^\+\d{11}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

  const validateForm = () => {
    if (!nameRegex.test(newUser.name)) {
      setError('Le nom ne doit contenir que des lettres.');
      return false;
    }
    if (newUser.name.length < 2) {
      setError('Le nom doit contenir au moins 2 caractères.');
      return false;
    }
    if (newUser.last_name.length < 2) {
      setError('Le nom de famille doit contenir au moins 2 caractères.');
      return false;
    }
    if (newUser.name.length > 30) {
      setError('Le nom ne doit pas contenir plus de 30 caractères.');
      return false;
    }
    if (newUser.last_name.length > 30) {
      setError('Le nom de famille ne doit pas contenir plus de 30 caractères.');
      return false;
    }
    if (!nameRegex.test(newUser.last_name)) {
      setError('Le nom de famille ne doit contenir que des lettres.');
      return false;
    }
    if (!emailRegex.test(newUser.email)) {
      setError("L'adresse e-mail n'est pas valide.");
      return false;
    }
    if (newUser.phone_number && !phoneRegex.test(newUser.phone_number)) {
      setError('Le numéro de téléphone doit être au format international : +33612345678');
      return false;
    }
    if (newUser.password !== user.password && !passwordRegex.test(newUser.password)) {
      setError(
        'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre.'
      );
      return false;
    }
    setError(null);
    return true;
  };

  const addAlertModesLabels = (modes) => {
    return modes.map((mode) => ({
      value: mode,
      label: mode
    }));
  };

  const removeAlertModesLabels = (modes) => {
    return modes.map((mode) => mode.value);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setIsModalOpen(true);
    }
  };

  const handleChange = (selected) => {
    console.log(removeAlertModesLabels(selected));
    setNewUser({ ...newUser, alert_modes: removeAlertModesLabels(selected) });
  };

  return (
    <>
      <CustomModal
        show={isModalOpen}
        title="Modification du user"
        submitButton={{
          text: 'Modifier',
          submit: () => {
            setIsModalOpen(false);
            // TODO: Remove this
            let tmpUser = newUser;
            delete tmpUser.admin_ids;
            requestUpdate(tmpUser);
          }
        }}
        cancelButton={{
          text: 'Annuler'
        }}
        onHide={() => setIsModalOpen(false)}
        isPrompt={false}>
        Êtes vous sûr de vouloir modifier vos informations ?
      </CustomModal>
      <div>
        <BreadcrumbL
          links={[
            ['/', 'Accueil'],
            ['/profile', 'Profil']
          ]}
        />
        <Row>
          <Col xs={12} className="mb-3">
            <h3>Modifier votre profil</h3>
            <Card>
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Subtitle className="text-muted"></Card.Subtitle>
                <Row>
                  <Col xs={12} className="mb-4 text-center">
                    <CardProfile />
                  </Col>
                </Row>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
                  <Row>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <FloatingLabel controlId="floatingName" label="Prénom">
                          <Form.Control
                            required
                            type="text"
                            placeholder=" "
                            value={newUser.name}
                            onChange={(e) => {
                              setNewUser({ ...newUser, name: e.target.value });
                            }}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <FloatingLabel controlId="floatingLastName" label="Nom">
                          <Form.Control
                            required
                            type="text"
                            placeholder=" "
                            value={newUser.last_name}
                            onChange={(e) => {
                              setNewUser({ ...newUser, last_name: e.target.value });
                            }}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <FloatingLabel controlId="floatingEmail" label="Email">
                          <Form.Control
                            required
                            type="email"
                            placeholder=" "
                            value={newUser.email}
                            onChange={(e) => {
                              setNewUser({ ...newUser, email: e.target.value });
                            }}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <FloatingLabel controlId="floatingPassword" label="Nouveau mot de passe">
                          <Form.Control
                            type="password"
                            placeholder=" "
                            onChange={(e) => {
                              setNewUser({ ...newUser, password: e.target.value });
                            }}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <FloatingLabel controlId="floatingPhone" label="Numéro de téléphone">
                          <Form.Control
                            type="text"
                            placeholder=" "
                            value={newUser.phone_number}
                            onChange={(e) => {
                              setNewUser({ ...newUser, phone_number: e.target.value });
                            }}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Select
                        options={ALERT_MODES}
                        value={addAlertModesLabels(newUser.alert_modes)}
                        onChange={handleChange}
                        isMulti={true}
                      />
                    </Col>
                    <Col xs={12} className="mb-3">
                      <Button type="submit">Modifier votre profil</Button>
                    </Col>
                  </Row>
                </Form>
                {error && <p className="text-danger">{error}</p>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserProfile;
