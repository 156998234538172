import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import LivomeImg from 'src/assets/images/livome.png';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';

const TermsOfUse = () => (
  <div data-cy="termofuse-div">
    <BreadcrumbL
      links={[
        ['/', 'Accueil'],
        ['/terms_of_use', 'Conditions générales']
      ]}
    />
    <Row>
      <Col xs="12">
        <Row className="text-center mt-3 mb-2">
          <Col xs="12">
            <h1>
              <strong>Conditions générales d'utilisation Livome</strong>
            </h1>
          </Col>
        </Row>
        <Row className="text-center mt-4 mb-2">
          <Col xs="12">
            <h5>Informations et mentions légales</h5>
            <em>Mise à jour le 10 octobre 2022</em>
          </Col>
        </Row>
        <Row className="text-center mt-3 mb-2">
          <Col xs="12">
            <Image src={LivomeImg} height="100px" />
          </Col>
        </Row>
        <Row className="mt-4 mb-2">
          <Col xs="12">
            <h5>
              <strong>Propriété du site, acceptation des conditions d'utilisation</strong>
            </h5>
            <p>
              Les présentes conditions générales d'utilisation s'appliquent au site web Livome sis à
              l'adresse http://livome.fr et à tous les sites associés liés au site www.livome.com
              par Livome, ses filiales, dans le monde entier. Le Site est la propriété d'Livome et
              de ses concédants.
              <strong>
                EN UTILISANT LE SITE, VOUS ACCEPTEZ LES PRÉSENTES CONDITIONS D'UTILISATION. SI VOUS
                NE LES ACCEPTEZ PAS, VEUILLEZ NE PAS UTILISER LE SITE.
              </strong>
              <br />
              <br />
              Livome se réserve le droit, à sa seule discrétion et à tout moment, de changer,
              modifier, compléter ou supprimer des parties de ces Conditions d'utilisation. Il est
              de votre responsabilité de consulter fréquemment ces Conditions d'utilisation pour
              voir si des modifications y ont été apportées. Si vous continuez à utiliser le Site
              après publication des modifications apportées, cela signifiera que vous acceptez
              lesdites modifications. Tant que vous vous conformez aux présentes Conditions
              d'utilisation, Livome vous accorde un droit personnel limité, non cessible d'accès au
              Site et d'utilisation du Site.
            </p>

            <br />
            <h5>
              <strong>Contenu</strong>
            </h5>
            <p>
              L'ensemble des graphiques, interfaces utilisateur et codes informatiques, notamment le
              design, la présentation et l'agencement de ce Contenu, figurant sur le Site est
              détenu, contrôlé ou cédé sous licence par ou à Livome, et est protégé par la
              législation, les droits d'auteur.
              <br />
              <br />
              Livome se reserve le droit, sans le consentement de l'utilisateur, de supprimer ou de
              modifier l'ensemble ou partie du contenu du Site, excepté les informations
              personnelles des utilisateurs.
            </p>

            <br />
            <h5>
              <strong>Vos données</strong>
            </h5>
            <p>
              En navigant sur le Site, vous acceptez de transmettre à Livome l'analyse de votre
              navigation sur le site. Les données que vous transmettez à Livome, via l'ensemble des
              services proposés, sont maintenues privées dans la mesure de leur visibilité première.
              Aucune information récoltée sur votre appareil ou information personnelle n'est
              vendue, cédée ou utilisée par Livome à d'autre fin que le bon fonctionnement du site.
              <br />
              Livome se dédouane de toute fuite d'information ou compromission de vos données.
              <br />
            </p>

            <br />
            <h5>
              <strong>Confidentialité</strong>
            </h5>
            <p>
              Il est de votre responsabilité de parler ou de faire des démonstrations de tout ou
              partie du contenu du Site aux personnes ne disposant pas de compte Livome. Les
              services proposés par Livome dépendent de votre discrétion, et peuvent, pour motifs de
              sécurité, être suspendus pour une durée indeterminée.
            </p>

            <br />
            <h5>
              <strong>Utilisation du Site</strong>
            </h5>
            <p>
              Vous ne devez pas essayer d'accéder de façon illicite à toute section ou
              fonctionnalité du Site, ni à tout autre système ou réseau connecté au Site ou à un
              serveur Livome, par piratage informatique, « reniflage » de mots de passe ou tout
              autre moyen illégitime.
              <br />
              <br />
              Vous ne devez pas prétendre être ou représenter quelqu'un d'autre, ni vous faire
              passer pour une autre entité physique ou morale.
              <br />
              <br />
              Vous ne devez pas utiliser le Site ou son Contenu dans un dessein illicite ou prohibé
              par les présentes Conditions d'utilisation, ni en vue d'encourager toute activité
              illégale ou autre portant atteinte aux droits d'Livome ou de tiers.
            </p>

            <br />
            <h5>
              <strong>Comptes, mots de passe et sécurité</strong>
            </h5>
            <p>
              La plupart des services et fonctionnalités offerts sur le Site nécessitent l'ouverture
              d'un compte. Vous avez l'entière responsabilité de préserver la confidentialité des
              informations relatives à votre compte, notamment votre mot de passe, et de toutes les
              opérations effectuées sous votre compte. Vous vous engagez à prévenir Livome
              immédiatement en cas d'utilisation non autorisée de votre compte ou de votre mot de
              passe, ou de toute violation de la sécurité. Toutefois, vous pourrez être tenu pour
              responsable des préjudices subis par Livome ou par tout autre utilisateur ou visiteur
              du Site dû à l'utilisation de votre compte, votre mot de passe ou votre compte par une
              autre personne.
              <br />
              <br />
              <strong>
                Vous n'êtes pas autorisé à utiliser le compte Livome, le mot de passe ou le compte
                d'une autre personne, à quelque moment que ce soit
              </strong>
              . Livome ne pourra être tenue pour responsable des préjudices découlant de votre
              manquement à respecter ces obligations.
            </p>

            <br />
            <h5>
              <strong>Infraction aux présentes conditions d'utilisation</strong>
            </h5>
            <p>
              Livome pourra divulguer les informations que nous détenons sur vous (y compris votre
              identité), si nous déterminons qu'une telle divulgation est nécessaire, en connexion
              avec une enquête concernant votre utilisation du Site, ou pour identifier ou contacter
              une personne soupçonnée de porter préjudice, volontairement ou non, aux droits ou la
              propriété d'Livome ou des utilisateurs du Site.
              <br />
              <br />
              Les infractions graves à l'encontre des présentes Conditions d'utilisation sont
              suceptible de mener, sans aucune forme d'avertissement, à la suppression totale ou
              partielle de votre accès aux services du site.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

export default TermsOfUse;
