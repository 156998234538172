import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PersonCircle } from 'react-bootstrap-icons';
import ClickOutside from './ClickOutsideHandler';
import {
  HouseFill,
  HospitalFill,
  PersonLinesFill,
  TelephoneFill,
  UniversalAccess
} from 'react-bootstrap-icons';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import useIsAdmin from '../../hooks/useIsAdmin';
import 'intro.js/introjs.css';

const NavLink = ({ link, icon, name }) => (
  <NavItem eventKey={link} aria-label={name + 'nav'}>
    <NavIcon aria-label={name + 'icon'}>
      <Link to={link} className="link_sidebar" aria-label={name}>
        <div className="w-100 h-100">{icon}</div>
      </Link>
    </NavIcon>
    <NavText>
      <Link to={link} className="link_sidebar">
        <div className="w-100 h-100">{name}</div>
      </Link>
    </NavText>
  </NavItem>
);

const NavLinka = ({ link, icon, name, target = '' }) => (
  <NavItem eventKey={link} aria-label={name + 'nav'}>
    <NavIcon aria-label={name + 'icon'}>
      <a href={link} className="link_sidebar" target={target} aria-label={name}>
        <div className="w-100 h-100">{icon}</div>
      </a>
    </NavIcon>
    <NavText>
      <a href={link} className="link_sidebar" target={target}>
        <div className="w-100 h-100">{name}</div>
      </a>
    </NavText>
  </NavItem>
);

const SideNavBar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const selected = useMemo(() => {
    const path = location.pathname;
    if (path.indexOf('patient') !== -1) return 'patients';
    if (path.indexOf('entity') !== -1) return 'entities';
    if (path.indexOf('user') !== -1) return 'users';
    return path.slice(1);
  }, [location.pathname]);
  const isAdmin = useIsAdmin();

  return (
    <ClickOutside
      onClickOutside={() => {
        setIsVisible(false);
      }}>
      <SideNav
        className="sidenav text-white"
        expanded={isVisible}
        onToggle={() => setIsVisible((isVisible) => !isVisible)}
        data-cy="side-nav">
        <SideNav.Toggle data-cy="menu-btn" />
        {/* A la place de defaultSelected il faudrait mettre la route actuelle */}
        <SideNav.Nav defaultSelected={selected || 'home'}>
          <NavItem
            eventKey="home"
            data-cy="menu-link-home"
            data-title="Accueil"
            data-intro="L'accueil vous permet de rejoindre la page du dashboard en un seul clic!">
            <NavLink name="Accueil" link="/home" icon={<HouseFill className="mb-1" />} />
          </NavItem>
          <NavItem
            eventKey="patients"
            data-cy="menu-link-patients"
            data-title="Patients"
            data-intro="L'onglet patient vous permet d'avoir un aperçu de tous les patients qui vous ont été associé(s), leur status et d'accéder à leur cahier de vie">
            <NavLink name="Patients" link="/patients" icon={<PersonLinesFill className="mb-1" />} />
          </NavItem>
          {isAdmin ? (
            <NavItem eventKey="users" data-cy="menu-link-users">
              <NavLink name="Utilisateurs" link="/users" icon={<PersonCircle className="mb-1" />} />
            </NavItem>
          ) : null}
          {isAdmin ? (
            <NavItem
              eventKey="entities"
              data-cy="menu-link-entities"
              data-title="Entités"
              data-intro="L'onglet des entités vous permet d'avoir un aperçu de toutes les entités">
              <NavLink name="Entités" link="/entities" icon={<HospitalFill className="mb-1" />} />
            </NavItem>
          ) : null}
          <NavItem
            eventKey="appels"
            data-cy="menu-link-call"
            data-title="Appel"
            data-intro="L'onglet d'appel vous permet de créer et/ou rejoindre une réunion via la videoconférence">
            <NavLinka
              name="Appels"
              link="https://livome.fr/videochat/"
              icon={<TelephoneFill className="mb-1" />}
              target="_blank"
            />
          </NavItem>
          <NavItem
            eventKey="aide"
            data-cy="menu-link-support"
            data-title="Aide"
            data-intro="L'onglet d'aide vous renvoit directement au centre d'aide d'un utilisateur, vous pourrez vous renseigner sur les différentes fonctionalités de la plateforme ou contacter le support">
            <NavLink name="Aide" link="/support" icon={<QuestionCircleFill className="mb-1" />} />
          </NavItem>
          <NavItem eventKey="accessibilité" data-cy="menu-link-accessibility">
            <NavLink
              name="Accessibilité"
              link="/accessibility"
              icon={<UniversalAccess className="mb-1" />}
            />
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </ClickOutside>
  );
};

// NavLink.propTypes = {
//   name: PropTypes.string.isRequired,
//   link: PropTypes.string.isRequired,
//   icon: PropTypes.element.isRequired
// };

export default SideNavBar;
