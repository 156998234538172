import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Actions from './Actions';
import Status from './Status';
import { tableCustomStyles } from './SearchPatientStyle';

const FilterComponent = ({ filterText, onFilter }) => (
  <>
    <span
      style={{
        position: 'absolute',
        top: 10,
        left: 10
      }}
      data-cy="list-search-add">
      <Link to={'/add_patient'}>
        <FaPlus
          className="addButton"
          style={{
            marginRight: 10
          }}
        />
        Ajouter un patient
      </Link>
    </span>
    <TextField
      id="input-with-icon-textfield"
      type="text"
      InputProps={{
        'data-cy': 'list-search-input',
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )
      }}
      placeholder="Rechercher un patient"
      variant="standard"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

FilterComponent.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filterText: PropTypes.string.isRequired
};

export const SearchPatient = ({ fetchPatients, data }) => {
  const [filterText, setFilterText] = useState('');
  const filteredItems = data.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.last_name && item.last_name.toLowerCase().includes(filterText.toLowerCase()))
  );

  const columns = [
    {
      id: 1,
      name: 'Prénom',
      cell: (row) => <span data-cy="list-search-row">{row.name}</span>,
      selector: (row) => row.name,
      sortable: true,
      reorder: true
    },
    {
      id: 2,
      name: 'Nom',
      cell: (row) => row.last_name,
      selector: (row) => row.last_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: 'Pathologie',
      cell: (row) => row.pathology,
      selector: (row) => row.pathology,
      sortable: true,
      reorder: true
    },
    {
      id: 4,
      name: 'Statut',
      cell: (row) => <Status patient={row} />,
      selector: (row) => row.status,
      sortable: true,
      reorder: true
    },
    {
      id: 5,
      name: 'Action',
      cell: (row) => <Actions patient={row} onDeleteClick={fetchPatients} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ];

  return (
    <DataTable
      customStyles={tableCustomStyles}
      className="smartList"
      columns={columns}
      data={filteredItems}
      subHeader
      subHeaderComponent={
        <FilterComponent onFilter={(e) => setFilterText(e.target.value)} filterText={filterText} />
      }
      pagination
    />
  );
};

SearchPatient.propTypes = {
  fetchPatients: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
};

export default SearchPatient;
