import React from 'react';
import SelectButton from './SelectButton';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';
import PropTypes from 'prop-types';
import IsAdminComponent from '../Authentification/RouteGuard/IsAdminComponent';

const Actions = ({ patient, onDeleteClick }) => {
  return (
    <>
      <SelectButton patient={patient} />
      <EditButton patient={patient} />
      <IsAdminComponent>
        <div data-cy="list-search-admin-btn" style={{ display: 'flex' }}>
          <DeleteButton patient={patient} onDeleteClick={onDeleteClick} />
        </div>
      </IsAdminComponent>
    </>
  );
};

Actions.propTypes = {
  patient: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};

export default Actions;
