import React, { useState } from 'react';
import { Badge, IconButton } from '@mui/material';
import { Bell } from 'react-bootstrap-icons';
('react-bootstrap-icons');
import { XLg } from 'react-bootstrap-icons';
import Modal from 'react-modal';
import './Notification.css';
import useSocket from 'src/hooks/useSocket';
import useCurrentUser from '../../hooks/useCurrentUser';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col } from 'react-bootstrap';

const NotificationMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  Modal.setAppElement('#root');

  const { data: notifications, setData: setNotifications } = useSocket({
    collection: 'users',
    documentID: user._id,
    defaultValue: user.notifications,
    callback: (data, change) => {
      return (
        change.updateDescription?.updatedFields?.notifications ??
        change.fullDocument?.notifications ??
        data
      );
    }
  });

  const { request: requestUpdate } = useRequest(URLS.USER.UPDATE(user._id));

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleDeleteModal = (id, notifications) => {
    const Notifications = notifications.filter((notification) => notification._id !== id);
    setNotifications(Notifications);
    const newNotif = { notifications: Notifications };
    requestUpdate(newNotif);
  };

  if (notifications.length === 0) {
    return (
      <div>
        <IconButton aria-label="Notifications" color="default" onClick={handleOpenModal}>
          <Bell color="white" />
        </IconButton>
        <Modal className="notification-modal" isOpen={isOpen} onRequestClose={handleCloseModal}>
          <div className="notification-list">Aucune notification</div>
        </Modal>
      </div>
    );
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
    </a>
  ));

  return (
    <div>
      <Badge badgeContent={notifications.length} color="error" max={10} overlap="circular">
        <Dropdown className="d-inline mx-1">
          <Dropdown.Toggle id="dropdown-autoclose-true" as={CustomToggle}>
            <IconButton
              data-title="Notifications"
              data-intro="Vous pouvez consulter vos notifications en cliquant sur ce bouton. Par défaut, en cas d'alerte d'un patient vous recevrez une notification"
              aria-label="Notifications"
              color="default"
              onClick={handleOpenModal}>
              <Bell color="white" />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" className="p-2" style={{ width: 400 }}>
            {notifications.map((notification) => (
              <div key={notification.patient_id}>
                <Dropdown.Item key={notification._id}>
                  <Row>
                    <Col
                      onClick={() => {
                        navigate(`/patient?id=${notification.patient_id}`);
                        handleCloseModal();
                      }}>
                      <Row>
                        <Col
                          xs={12}
                          className="text-wrap"
                          style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                          {notification.title}
                        </Col>
                        <Col xs={12} className="text-wrap">
                          {notification.desc}
                        </Col>
                        <Col xs={12}>
                          {new Date(notification.date).toLocaleDateString(navigator.language, {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={2}>
                      <IconButton
                        aria-label="Delete"
                        color="error"
                        onClick={() => handleDeleteModal(notification._id, notifications)}>
                        <XLg />
                      </IconButton>
                    </Col>
                  </Row>
                </Dropdown.Item>
                <Dropdown.Divider />
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Badge>
      {/* <Modal className="notification-modal" isOpen={isOpen} onRequestClose={handleCloseModal}>
        <div className="notification-list"></div>
      </Modal> */}
    </div>
  );
};

export default NotificationMenu;
