import React, { useEffect } from 'react';
import useSocket from 'src/hooks/useSocket';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { CheckCircle, XCircle } from 'react-bootstrap-icons';
import { STATUS } from 'src/Enums';
import Spinner from '../Spinner/Spinner';

const DashboardAlert = () => {
  const navigate = useNavigate();
  const { data: patients, request, loading } = useRequest(URLS.PATIENT.GETALL());
  const { data: socketPatients } = useSocket({
    collection: 'patients',
    actions: ['create', 'update', 'replace'],
    defaultValue: patients ?? [],
    callback: (currentData, change) => {
      return currentData.map((patient) => {
        if (change.fullDocument && patient._id === change.fullDocument._id)
          return change.fullDocument;
        if (change.updateDescription && patient._id === change.documentKey._id)
          return { ...patient, ...change.updateDescription.updatedFields };

        return patient;
      });
    }
  });

  useEffect(() => {
    request();
  }, []);

  if (loading)
    return (
      <Row data-intro="Voir les alertes">
        <Col xs={12}>
          <h3>Alertes</h3>
        </Col>
        <Col className="col-12 my-2">
          <Spinner />
        </Col>
      </Row>
    );

  if (socketPatients.filter((patient) => patient.status === STATUS.UNSTABLE).length === 0) {
    return (
      <Row data-intro="Voir les alertes" data-cy="no-alert-patient">
        <Col xs={12}>
          <h3>Alertes</h3>
        </Col>
        <Col className="col-12 my-2">
          <Alert variant="success">
            <Alert.Heading>
              <CheckCircle className="mb-1" /> Tout va bien !
            </Alert.Heading>
            <p className="mb-0">
              Aucun de nos dispositifs n'a déclenché une alerte.
              <br />
              Les constantes des patients ne présentent aucune anormalité.
              <br />
              <br />
              <em>
                Si les constantes vitales d'un patient dépassent le seuil indiqué, vous en serez
                informé sur cette plateforme ou via les moyen de communication que vous nous avez
                indiqués.
              </em>
            </p>
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <Row data-intro="Voir les alertes">
      <Col xs={12}>
        <h3>Alertes</h3>
      </Col>
      <Col className="col-12 my-2">
        <Alert show={true} variant="danger">
          <Row>
            <Col xs={12} className="mb-3">
              <h4>
                <XCircle className="mb-1" /> Alerte |{' '}
                {socketPatients.filter((patient) => patient.status === STATUS.UNSTABLE).length}
                {socketPatients.filter((patient) => patient.status === STATUS.UNSTABLE).length === 1
                  ? ' patient instable'
                  : ' patients instables'}
              </h4>
            </Col>
            <Col xs={12}>
              <Row>
                {socketPatients
                  .filter((patient) => patient.status === STATUS.UNSTABLE)
                  .map((patient) => (
                    <Col xs={12} key={patient._id}>
                      <Button
                        className="w-100 text-center"
                        variant="danger"
                        onClick={() => navigate(`/patient?id=${patient._id}`)}>
                        {patient.name + ' ' + patient.last_name}
                      </Button>
                      <hr className="my-1"></hr>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </Alert>
      </Col>
    </Row>
  );
};

export default DashboardAlert;
