import React from 'react';
import EditButton from './EditButtonUser';
import DeleteButton from './DeleteButtonUser';

const UserActions = ({ onDeleteClick, onModifyClick }) => {
  return (
    <>
      <EditButton onModifyClick={onModifyClick} />
      <DeleteButton onDeleteClick={onDeleteClick} />
    </>
  );
};

export default UserActions;
