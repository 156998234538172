import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import { Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap';
import CustomModal from '../Modals/CustomModal';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import Spinner from '../Spinner/Spinner';

const ModifyPatient = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { request, loading } = useRequest(URLS.PATIENT.GET(searchParams.get('id')));

  const { request: getMotherEntityRequest, data: motherEntities } = useRequest(
    URLS.MOTHERENTITY.GETALL()
  );

  const [patientData, setPatientData] = useState(null);
  const { request: requestUpdate } = useRequest(URLS.PATIENT.UPDATE(searchParams.get('id')), {
    body: patientData
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderChildren = (children, depth) => {
    return children.map((child) => (
      <>
        {child.children.length ? (
          <>
            <option value={child._id} key={child._id} disabled>
              {new Array(depth * 4).forEach(() => '&nbsp;')}
              {child.name}
            </option>
            {renderChildren(child.children, depth + 1)}
          </>
        ) : (
          <option key={child._id} value={child._id}>
            &nbsp;&nbsp;&nbsp;&nbsp;{child.name}
          </option>
        )}
      </>
    ));
  };

  useEffect(() => {
    request().then(({ data }) => {
      setPatientData(data);
    });
    if (motherEntities) return;
    getMotherEntityRequest();
  }, []);

  if (loading || !patientData) return <Spinner />;

  return (
    <>
      <CustomModal
        show={isModalOpen}
        title="Modification du patient"
        submitButton={{
          text: 'Modifier',
          submit: () => {
            requestUpdate();
            navigate('/patients');
          }
        }}
        cancelButton={{
          text: 'Annuler'
        }}
        onHide={() => setIsModalOpen(false)}
        isPrompt={false}>
        Êtes vous sûr de vouloir modifier les informations du patient ?
      </CustomModal>
      <div>
        <BreadcrumbL
          links={[
            ['/', 'Accueil'],
            ['/patients', 'Patients'],
            [
              `/modify_patient?id=${searchParams.get('id')}`,
              `${patientData.name} ${patientData.last_name}`
            ]
          ]}
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}>
          <Row className="justify-content-center align-items-center" xs={10}>
            <Col md={5}>
              <h3 className="mb-5 text-center font-bold">Modification du patient</h3>
              <Row className="mb-4">
                <h4>Informations patient</h4>
              </Row>

              <Row>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatingFirstName" label="Prénom">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        value={patientData.name}
                        onChange={(event) => {
                          setPatientData({ ...patientData, name: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatinglast_name" label="Nom">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        value={patientData.last_name}
                        onChange={(event) => {
                          setPatientData({ ...patientData, last_name: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatingFirstName" label="Email">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        value={patientData.email}
                        onChange={(event) => {
                          setPatientData({ ...patientData, email: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group>
                    <FloatingLabel controlId="floatingLastName" label="Numéro">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        value={patientData.phone_number}
                        onChange={(event) => {
                          setPatientData({ ...patientData, phone_number: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Col className="mb-4">
                <Row>
                  <Col>
                    <Form.Group>
                      <FloatingLabel controlId="floatingBirthDate" label="Date de naissance">
                        <Form.Control
                          required
                          type="date"
                          placeholder=" "
                          value={patientData.birthday?.slice(0, 10)}
                          onChange={(event) => {
                            setPatientData({ ...patientData, birthday: event.target.value });
                          }}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <FloatingLabel controlId="floatingPathology" label="Pathologie">
                        <Form.Control
                          required
                          type="text"
                          placeholder=" "
                          value={patientData.pathology}
                          onChange={(event) => {
                            setPatientData({ ...patientData, pathology: event.target.value });
                          }}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Row className="mb-4">
                <h4>Informations système</h4>
              </Row>
              <Row xs={12} md={2} className="mb-3">
                <Col>
                  <Form.Group>
                    <FloatingLabel controlId="floatingSelect" label="Section">
                      {loading || !patientData?.motherEntity_id ? (
                        <Spinner />
                      ) : (
                        <Form.Select
                          aria-label="Floating label select example"
                          required
                          type="text"
                          value={patientData?.motherEntity_id?._id}
                          onChange={(event) => {
                            setPatientData((oldState) => ({
                              ...oldState,
                              motherEntity_id: event.target.value
                            }));
                          }}>
                          {motherEntities &&
                            motherEntities.map((motherEntity) =>
                              motherEntity.children.length ? (
                                <optgroup label={motherEntity.name} key={motherEntity._id}>
                                  {renderChildren(motherEntity.children, 1)}
                                </optgroup>
                              ) : (
                                <option value={motherEntity._id} key={motherEntity._id}>
                                  {motherEntity.name}
                                </option>
                              )
                            )}
                        </Form.Select>
                      )}
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={12} className="">
                  <Button type="submit">Modifier le patient</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ModifyPatient;
