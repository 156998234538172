export const ROLE = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  STAFF: 'STAFF'
} as const;

export const STATUS = {
  STABLE: 'Stable',
  UNSTABLE: 'Instable',
  UNKNOWN: 'Inconnu'
} as const;
