import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';

const SelectButton = ({ patient }) => (
  <div data-cy="list-search-select" className="smartListSelect text-blue">
    <Link to={'/patient?id=' + patient._id}>
      <IconButton aria-label="visibility" color="info" className="text-blue">
        <Visibility className="text-blue" />
      </IconButton>
    </Link>
  </div>
);

SelectButton.propTypes = {
  patient: PropTypes.object.isRequired
};

export default SelectButton;
