import React from 'react';
import IconButton from '@mui/material/IconButton';
import ModeEdit from '@mui/icons-material/ModeEdit';

const EditButtonUser = ({ onModifyClick }) => {
  return (
    <div>
      <IconButton aria-label="ModeEdit" onClick={onModifyClick}>
        <ModeEdit />
      </IconButton>
    </div>
  );
};

export default EditButtonUser;
