import React, { useEffect, useState } from 'react';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import { Row, Col, FloatingLabel, Form, Button } from 'react-bootstrap';
import useCurrentUser from '../../hooks/useCurrentUser';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import Spinner from '../Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../Modals/CustomModal';
const AddPatient = () => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { request } = useRequest(URLS.PATIENT.CREATE());

  const {
    request: getMotherEntityRequest,
    data: motherEntities,
    loading
  } = useRequest(URLS.MOTHERENTITY.GETALL());

  const [patientData, setPatientData] = useState({
    name: '',
    last_name: '',
    birthday: '',
    pathology: '',
    user_id: user._id,
    motherEntity_id: '',
    email: '',
    phone_number: '',
    watch_mac_address: '00:00:00:00:00:01'
  });

  useEffect(() => {
    if (motherEntities) return;
    getMotherEntityRequest().then(({ data }) => {
      {
        setPatientData({
          ...patientData,
          motherEntity_id: data[0]._id
        });
      }
    });
  }, [patientData.motherEntity_id]);

  const renderChildren = (children, depth) => {
    return children.map((child) => (
      <>
        {child.children.length ? (
          <>
            <option value={child._id} key={child._id} disabled>
              {new Array(depth * 4).forEach(() => '&nbsp;')}
              {child.name}
            </option>
            {renderChildren(child.children, depth + 1)}
          </>
        ) : (
          <option key={child._id} value={child._id}>
            &nbsp;&nbsp;&nbsp;&nbsp;{child.name}
          </option>
        )}
      </>
    ));
  };

  return (
    <>
      <CustomModal
        show={isModalOpen}
        title="Ajout du patient"
        submitButton={{
          text: 'Ajouter',
          submit: () => {
            request(patientData);
            navigate('/patients');
          }
        }}
        cancelButton={{
          text: 'Annuler'
        }}
        onHide={() => setIsModalOpen(false)}
        isPrompt={false}>
        Êtes vous sûr de vouloir ajouter ce patient ?
      </CustomModal>
      <div>
        <BreadcrumbL
          links={[
            ['/', 'Accueil'],
            ['/add_patient', 'Nouveau patient']
          ]}
        />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}>
          <Row className="justify-content-center align-items-center" xs={10}>
            <Col md={5}>
              <h3 className="mb-5 text-center font-bold">Formulaire d'ajout de patient</h3>
              <Row className="mb-4">
                <h4>Informations patient</h4>
              </Row>

              <Row>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatingFirstName" label="Prénom">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        onChange={(event) => {
                          setPatientData({ ...patientData, name: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatinglast_name" label="Nom">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        onChange={(event) => {
                          setPatientData({ ...patientData, last_name: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <Form.Group>
                    <FloatingLabel controlId="floatingFirstName" label="Email">
                      <Form.Control
                        required
                        type="text"
                        placeholder=" "
                        onChange={(event) => {
                          setPatientData({ ...patientData, email: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group>
                    <FloatingLabel controlId="floatingLastName" label="Numéro">
                      <Form.Control
                        type="text"
                        placeholder=" "
                        onChange={(event) => {
                          setPatientData({ ...patientData, phone_number: event.target.value });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Col className="mb-4">
                <Row>
                  <Col>
                    <Form.Group>
                      <FloatingLabel controlId="floatingBirthDay" label="Date de naissance">
                        <Form.Control
                          required
                          type="date"
                          placeholder=" "
                          onChange={(event) => {
                            setPatientData({ ...patientData, birthday: event.target.value });
                          }}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <FloatingLabel controlId="floatingPathology" label="Pathologie">
                        <Form.Control
                          required
                          type="text"
                          placeholder=" "
                          onChange={(event) => {
                            setPatientData({ ...patientData, pathology: event.target.value });
                          }}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Row className="mb-4">
                <h4>Informations système</h4>
              </Row>
              <Row xs={12} md={2} className="mb-3">
                <Col>
                  <Form.Group>
                    <FloatingLabel controlId="floatingSelect" label="Select one">
                      {loading ? (
                        <Spinner />
                      ) : (
                        <Form.Select
                          aria-label="Floating label select example"
                          required
                          defaultValue={motherEntities[0]._id}
                          onChange={(event) => {
                            setPatientData({
                              ...patientData,
                              motherEntity_id: event.target.value
                            });
                          }}>
                          {motherEntities &&
                            motherEntities.map((motherEntity) =>
                              motherEntity.children.length ? (
                                <optgroup label={motherEntity.name} key={motherEntity._id}>
                                  {renderChildren(motherEntity.children, 1)}
                                </optgroup>
                              ) : (
                                <option value={motherEntity._id} key={motherEntity._id}>
                                  {motherEntity.name}
                                </option>
                              )
                            )}
                        </Form.Select>
                      )}
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <FloatingLabel controlId="floatingMacAddress" label="Adresse MAC">
                      <Form.Control
                        required
                        type="text"
                        placeholder=""
                        value={patientData.watch_mac_address}
                        onChange={(event) => {
                          setPatientData({
                            ...patientData,
                            watch_mac_address: event.target.value
                          });
                        }}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs={12} className="">
                  <Button type="submit">Créer un patient</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default AddPatient;
