import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const AppContext = createContext({
  user: null as object | null,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setUser: (_user: object | null) => {}
});

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState<object | null>(null);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser
      }}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node
};

export default AppContextProvider;
