import React, { useEffect, useState } from 'react';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import { Row, Col, FloatingLabel, Form, Button, Spinner } from 'react-bootstrap';
import useCurrentUser from '../../hooks/useCurrentUser';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';
import { useNavigate } from 'react-router-dom';
import { ROLE } from 'src/Enums';

const AddUser = () => {
  const { user } = useCurrentUser();
  const navigate = useNavigate();

  const { request } = useRequest(URLS.USER.CREATE());
  const { request: createLink } = useRequest(URLS.USERENTITY.CREATE());
  const [userData, setUserData] = useState({
    name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    role: ROLE.USER,
    admin_ids: [user._id]
  });
  const {
    request: getMotherEntityRequest,
    data: motherEntities,
    loading
  } = useRequest(URLS.MOTHERENTITY.GETALL());
  const [motherEntity_id, setMotherEntity_id] = useState('');

  console.log('motherEntity_id', motherEntity_id);

  const handleSubmit = (event) => {
    event.preventDefault();
    request(userData).then(({ data }) => {
      createLink({
        user_id: data._id,
        motherEntity_id: motherEntity_id
      }).then(() => navigate('/users'));
    });
  };

  useEffect(() => {
    getMotherEntityRequest().then(({ data: motherEntities }) => {
      setMotherEntity_id(motherEntities[0]._id);
    });
  }, []);

  const renderChildren = (children, depth) => {
    return children.map((child) => (
      <>
        {child.children.length ? (
          <>
            <option value={child._id} key={child._id}>
              {new Array(depth * 4).forEach(() => '&nbsp;')}
              {child.name}
            </option>
            {renderChildren(child.children, depth + 1)}
          </>
        ) : (
          <option key={child._id} value={child._id}>
            &nbsp;&nbsp;&nbsp;&nbsp;{child.name}
          </option>
        )}
      </>
    ));
  };

  return (
    <div>
      <BreadcrumbL
        links={[
          ['/', 'Accueil'],
          ['/add_user', 'Nouvel utilisateur']
        ]}
      />
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center align-items-center" xs={10}>
          <Col md={5}>
            <h3 className="mb-5 text-center font-bold">Formulaire d'ajout d'un utilisateur</h3>
            <Row className="mb-4">
              <h4>Informations de l'utilisateur</h4>
            </Row>

            <Row>
              <Col xs={6}>
                <Form.Group>
                  <FloatingLabel controlId="floatingFirstName" label="Prénom">
                    <Form.Control
                      required
                      type="text"
                      placeholder=" "
                      onChange={(event) => {
                        setUserData({ ...userData, name: event.target.value });
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={12} md={6} className="mb-3">
                <Form.Group>
                  <FloatingLabel controlId="floatinglast_name" label="Nom">
                    <Form.Control
                      required
                      type="text"
                      placeholder=" "
                      onChange={(event) => {
                        setUserData({ ...userData, last_name: event.target.value });
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <Form.Group>
                  <FloatingLabel controlId="floatingEmail" label="Email">
                    <Form.Control
                      required
                      type="text"
                      placeholder=" "
                      onChange={(event) => {
                        setUserData({ ...userData, email: event.target.value });
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group>
                  <FloatingLabel controlId="floatingPhoneNumber" label="Numéro">
                    <Form.Control
                      required
                      type="text"
                      placeholder=" "
                      onChange={(event) => {
                        setUserData({ ...userData, phone_number: event.target.value });
                      }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row xs={12} className="mb-3">
              <Col>
                <Form.Group>
                  <FloatingLabel controlId="floatingSelect" label="Select one">
                    {loading || !motherEntities.length ? (
                      <Spinner />
                    ) : (
                      <Form.Select
                        aria-label="Floating label select example"
                        required
                        defaultValue={motherEntities[0]._id}
                        onChange={(event) => {
                          setMotherEntity_id(event.target.value);
                        }}>
                        {motherEntities &&
                          motherEntities.map((motherEntity) => (
                            <>
                              <option value={motherEntity._id} key={motherEntity._id}>
                                {motherEntity.name}
                              </option>
                              {renderChildren(motherEntity.children, 0)}
                            </>
                          ))}
                      </Form.Select>
                    )}
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <FloatingLabel controlId="floatingSelect" label="Select one">
                    <Form.Select
                      aria-label="Floating label select example"
                      required
                      defaultValue={ROLE.USER}
                      onChange={(event) => {
                        setUserData({ ...userData, role: event.target.value });
                      }}>
                      <option value={ROLE.USER}>Utilisateur</option>
                      <option value={ROLE.ADMIN}>Administrateur</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} className="">
                <Button type="submit">Créer un utilisateur</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddUser;
