import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import CustomModal from '../Modals/CustomModal';
import useRequest from 'src/hooks/useRequest';
import PropTypes from 'prop-types';
import { URLS } from 'src/globals';

const FormThreshold = ({ patient }) => {
  const [lowerThresholdData, setLowerThresholdData] = useState(
    patient.thresholds.lower ?? { spo2: 90, temperature: 38, pulse_rate: 150 }
  );
  const [upperThresholdData, setUpperThresholdData] = useState(
    patient.thresholds.upper ?? { spo2: 90, temperature: 38, pulse_rate: 150 }
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { request } = useRequest(URLS.PATIENT.UPDATE(patient._id), {
    body: {
      thresholds: {
        lower: lowerThresholdData,
        upper: upperThresholdData
      }
    }
  });

  return (
    <>
      {showConfirmModal && (
        <CustomModal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          submitButton={{
            text: 'Confirmer',
            variant: 'primary',
            submit: request
          }}
          cancelButton={{
            text: 'Annuler',
            variant: 'secondary'
          }}
          title="Êtes-vous sûr de vouloir modifier les limites du patient ?"
          isPrompt={false}
        />
      )}
      <Form>
        <Row>
          <h5>Seuil minimal</h5>
          <Form.Group as={Col} controlId="formSpo2">
            <Form.Label>SPO2</Form.Label>
            <Form.Control
              type="number"
              value={lowerThresholdData.spo2}
              onChange={(e) =>
                setLowerThresholdData({ ...lowerThresholdData, spo2: e.target.valueAsNumber })
              }
              data-cy="cdv-lower-formcontrol-spo2"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formTemperature">
            <Form.Label>Température</Form.Label>
            <Form.Control
              type="number"
              value={lowerThresholdData.temperature}
              onChange={(e) =>
                setLowerThresholdData({
                  ...lowerThresholdData,
                  temperature: e.target.valueAsNumber
                })
              }
              data-cy="cdv-lower-formcontrol-temperature"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formpulse_rate">
            <Form.Label>Rythme cardiaque</Form.Label>
            <Form.Control
              type="number"
              value={lowerThresholdData.pulse_rate}
              onChange={(e) =>
                setLowerThresholdData({ ...lowerThresholdData, pulse_rate: e.target.valueAsNumber })
              }
              data-cy="cdv-lower-formcontrol-pulse_rate"
            />
          </Form.Group>
        </Row>
        <br />
        <h5>Seuil maximal</h5>
        <Row>
          <Form.Group as={Col} controlId="formSpo22">
            <Form.Label>SPO2</Form.Label>
            <Form.Control
              type="number"
              value={upperThresholdData.spo2}
              onChange={(e) =>
                setUpperThresholdData({ ...upperThresholdData, spo2: e.target.valueAsNumber })
              }
              data-cy="cdv-upper-formcontrol-spo2"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formTemperature2">
            <Form.Label>Température</Form.Label>
            <Form.Control
              type="number"
              value={upperThresholdData.temperature}
              onChange={(e) =>
                setUpperThresholdData({
                  ...upperThresholdData,
                  temperature: e.target.valueAsNumber
                })
              }
              data-cy="cdv-upper-formcontrol-temperature"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formpulse_rate2">
            <Form.Label>Rythme cardiaque</Form.Label>
            <Form.Control
              type="number"
              value={upperThresholdData.pulse_rate}
              onChange={(e) =>
                setUpperThresholdData({ ...upperThresholdData, pulse_rate: e.target.valueAsNumber })
              }
              data-cy="cdv-upper-formcontrol-pulse_rate"
            />
          </Form.Group>
        </Row>
        <Button
          className="w-100 mt-2"
          onClick={() => setShowConfirmModal(true)}
          data-cy="cdv-formcontrol-submit">
          Modifier
        </Button>
      </Form>
    </>
  );
};

FormThreshold.propTypes = {
  patient: PropTypes.object.isRequired
};

export default FormThreshold;
