import React, { useState, useEffect } from 'react';
import { Row, Card } from 'react-bootstrap';
import Spinner from 'src/components/Spinner/Spinner';
import BreadcrumbL from 'src/components/Breadcrumb/Breadcrumb';
import NestedFilterButtons from './NestedFilterButtons';
import { SearchPatient } from './SearchPatient';
import useRequest from 'src/hooks/useRequest';
import { URLS } from 'src/globals';

const PatientList = () => {
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const {
    data: allPatients,
    request: patientsRequest,
    loading: loadingPatients
  } = useRequest(URLS.PATIENT.GETALL());
  const { request: motherEntitiesRequest, loading: loadingMotherEntities } = useRequest(
    URLS.MOTHERENTITY.GETALL()
  );

  const fetchPatients = () => {
    patientsRequest().then(({ data }) => setFilteredPatients(data));
  };

  useEffect(() => {
    fetchPatients();
    motherEntitiesRequest().then(({ data: motherEntities }) => {
      if (!Array.isArray(motherEntities)) return;
      const getCheckboxStates = (motherEntities) =>
        motherEntities.map((motherEntity) => ({
          id: motherEntity._id,
          label: motherEntity.name,
          children: getCheckboxStates(motherEntity.children)
        }));
      setCheckboxes(getCheckboxStates(motherEntities));
    });
  }, []);

  const handleCheckboxChange = (selectedMotherEntities) => {
    const selectedMotherEntitiesIDs = selectedMotherEntities.map((motherEntity) => motherEntity.id);
    const filteredPatients = allPatients.filter((patient) =>
      selectedMotherEntitiesIDs.includes(patient.motherEntity_id._id)
    );
    setFilteredPatients(filteredPatients);
  };

  if (loadingPatients || loadingMotherEntities) return <Spinner />;

  return (
    <div>
      <BreadcrumbL
        links={[
          ['/', 'Accueil'],
          ['/patients', 'Patients']
        ]}
      />
      <Row>
        <h3>Liste des patients</h3>
      </Row>
      <Row style={{ marginBottom: 10, marginTop: 5 }} data-cy="list-entity">
        {checkboxes && checkboxes.length > 0 && (
          <NestedFilterButtons items={checkboxes} onChange={handleCheckboxChange} />
        )}
      </Row>
      <Row>
        <Card>
          <Card.Body data-cy="list-search-patient" className="smartList">
            <SearchPatient fetchPatients={fetchPatients} data={filteredPatients} />
          </Card.Body>
        </Card>
      </Row>
    </div>
  );
};

export default PatientList;
