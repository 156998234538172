import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const ClickOutside = ({ onClickOutside, children }) => {
  const ref = useRef(this);

  const handle = (e) => {
    if (ref.current && !ref.current.contains(e.target)) onClickOutside(e);
  };

  useEffect(() => {
    document.addEventListener('click', handle, true);
    return () => {
      document.removeEventListener('click', handle, true);
    };
  }, []);

  return <div ref={ref}>{children}</div>;
};

ClickOutside.propTypes = {
  onClickOutside: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default ClickOutside;
