import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

const PlageSchedule = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const [showPlage, setShowPlage] = useState(false);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div>
      {!showPlage ? (
        <Button variant="primary" data-cy="show-schedule" onClick={() => setShowPlage(!showPlage)}>
          Afficher plage horaire
        </Button>
      ) : (
        <div>
          <Button variant="danger" data-cy="hide-schedule" onClick={() => setShowPlage(!showPlage)}>
            Masquer plage horaire
          </Button>
          <br />
          <br />
          <div data-cy="cdv-schedule">
            <DatePicker
              onChange={handleDateChange}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              inline={true}
              calendarStartDay={1}
              selectsRange
              locale="fr"
            />
          </div>
        </div>
      )}
    </div>
  );
};

PlageSchedule.propTypes = {
  startDate: PropTypes.object,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.object,
  setEndDate: PropTypes.func.isRequired
};

export default PlageSchedule;
