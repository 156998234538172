import './Menu.css';
import React, { useEffect, useState } from 'react';
// import avatar from 'src/assets/images/avatar.png';
import { Row, Col, Navbar, NavDropdown, Image } from 'react-bootstrap';
import Logo from 'src/assets/images/logo_reverse.png';
import useCurrentUser from '../../hooks/useCurrentUser';
import NotificationMenu from './NotificationMenu';
import introJs from 'intro.js';
import { PersonCircle } from 'react-bootstrap-icons';
import { URLS } from 'src/globals';
import envURL from 'src/Env';

const HeaderBar = () => {
  const { logout } = useCurrentUser();

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const url = URLS.IMAGE.GET().route;

    fetch(`${envURL}${url}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken'))}`
      }
    }).then(async (response) => {
      if (response.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
      }
    });
  }, []);

  return (
    <Navbar fixed="top" className="topnav navbar-expand-xl py-1 m-0 w-100" data-cy="navbar">
      <div className="p-0 m-0 w-100 container-fluid">
        <Navbar.Brand href="/">
          <Row>
            <Col xs={3}>
              <Image
                src={Logo}
                height="30px"
                width="30px"
                className="rounded-circle mb-1"
                alt="Logo Livome"
              />
            </Col>
            <Col xs={7} className="text-white px-3">
              Livome
            </Col>
          </Row>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end px-0">
          <NotificationMenu />
          <NavDropdown
            data-cy="nav-dropdown"
            data-title="Profil utilisateur"
            data-intro="Vous pouvez consulter votre profil et y effectuer des modifications si necessaire. Sur cet onglet vous pourrez également vous déconnecter ou consulter les notes d'information"
            data-testid="nav-dropdown"
            align="end"
            className="px-0 navIconUser"
            style={{ height: '30px' }}
            title={
              imageUrl ? (
                <Image
                  src={imageUrl}
                  className="mx-2"
                  height="30px"
                  width="30px"
                  roundedCircle
                  alt="Photo de profil"
                />
              ) : (
                <PersonCircle className="mx-2" height="30px" width="30px" alt="Photo de profil" />
              )
            }
            id="basic-nav-dropdown">
            <NavDropdown.Item href="/profile">Profil</NavDropdown.Item>
            <NavDropdown.Divider />
            <div data-cy="logout-button">
              <NavDropdown.Item onClick={logout}>Se déconnecter</NavDropdown.Item>
            </div>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => introJs().start()}>Informations</NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default HeaderBar;
