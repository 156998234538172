import './Spinner.scss';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Spinner = () => (
  <Row data-cy="loading-spinner">
    <Col xs={12}>
      <div className="circle">
        <svg
          width="150"
          height="150"
          xmlns="http://www.w3.org/2000/svg"
          className="page-loader-logo"
          viewBox="0 0 100 100">
          <path
            className="derp"
            stroke="#FFF"
            fill="none"
            strokeMiterlimit="10"
            d="M0 29.9h17c2.667 0 4.667-.667 6-2 2-2 3-2.5 4 .75a141.496 141.496 0 0 1 2 7.25c.667 2.667 1.667 1 3-5s3.667-15.333 7-28c.667-2 1-.667 1 4 0 3.85.227 9.287.68 16.312.097 1.491.356 5.854.427 6.223.147.758.444.914.893.465 1-1 2-3 4-6s2-1 5 1c2 1.333 4.333 2 7 2"
            fillRule="evenodd"
          />
        </svg>
      </div>
    </Col>
  </Row>
);

export default Spinner;
